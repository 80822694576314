// ## BUTTONS:
.g_btn {
  @include font-regular();
  @include font-size(16);

  display: inline-block;

  padding: 8px 16px 8px 16px;
  max-height: 40px;
  border: none;
  border-radius: $border-radius-global;
  background-color: $color-unnamed1;
  color: $color-white;
  vertical-align: top;
  text-decoration: none;
  text-shadow: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, border 0.2s ease-out,
    color 0.2s ease-out;
  pointer-events: all;

  &:focus,
  &:hover {
    background-color: $color-grey4;
  }
  &:active {
    background-color: $color-grey3;
  }
  &.disabled {
    @include opacity(50);

    color: $color-white;
    cursor: default;
    &:active,
    &:hover {
      background-color: $color-grey1;
      color: $color-white;
    }
  }
  &:focus {
    outline: none;
  }
  &.g_btn-block {
    display: block;

    text-align: center;
  }
  &.g_btn-large {
    @media #{$medium-up} {
      padding: 11px 16px 12px 16px;
      max-height: 48px;
    }
  }
  &.g_btn-fullWidth {
    width: 100%;
    text-align: center;
  }
  &.g_btn-minimal {
    @include font-size(12, 16);

    padding: 8px 10px;
    max-height: 32px;

    .g_btn-minimal_icon {
      height: auto;
      transition: fill 0.2s ease-in-out;
      fill: $color-grey3;
    }
    &:hover .g_btn-minimal_icon {
      fill: $color-white;
    }
    &:focus .g_btn-minimal_icon {
      fill: $color-grey4;
    }
    & + .g_btn-minimal {
      margin-left: 4px;
    }
  }
  &.g_btn-transparent {
    background-color: transparent;
    color: $color-grey3;
    .g_btn_svg-followStyle {
      fill: $color-grey3;
    }
    &:hover {
      background-color: $color-grey6;
      color: $color-grey1;
      .g_btn_svg-followStyle {
        fill: $color-grey1;
      }
    }
  }

  &.g_btn-small {
    @include font-size(14, 16);
  }

  // TODO: clean up these span, svg selectors
  // Most of the following will be replaced by g_btn_svg
  span {
    margin: 0;

    padding: 0;
  }

  svg + span {
    display: inline-block;

    padding-left: 4px;
    vertical-align: top;
  }
  .eyecon + span {
    // make sure there is a decent padding between icons and the following element
    margin-left: 12px;
    // in case the icon is bigger that vertical-align makes sure the text stays in it's line

    vertical-align: top;
  }

  // should be used for all buttons that contain svgs
  // TODO: sort out generall image positioning rules with design (-> living styleguide)
  .g_btn_svg {
    &.g_btn_svg-labeled {
      position: absolute;
      left: 12px;
      top: calc(50% - 9px);
    }

    // we want transitions on color changes in svgs
    * {
      transition: fill 0.2s ease-in-out;
    }
  }
}

// colors:
.g_btn {
  &.g_btn-white {
    background-color: $color-white;
    color: $color-black1;

    &:focus,
    &:hover {
      background-color: $color-unnamed28;
    }
    &:active {
      background-color: $color-unnamed12;
    }
    &.disabled {
      background-color: $color-black1;
    }
  }
  &.g_btn-aqua {
    background-color: $color-aqua;
    &:focus,
    &:hover {
      background-color: $color-aquaHover;
    }
    &:active {
      background-color: $color-aquaActive;
    }
    &.disabled {
      &:active,
      &:focus,
      &:hover {
        background-color: $color-aqua;
      }
    }
    &.g_btn-spinner:hover {
      background-color: $color-aqua;
      cursor: default;
    }
  }
  &.g_btn-bluePaypal {
    background-color: $color-paypal;
    &:focus,
    &:hover {
      background-color: $color-black1;
    }
    &:active {
      background-color: $color-black1;
    }
    &.disabled {
      &:active,
      &:hover {
        background-color: $color-paypal;
      }
    }
  }
  &.g_btn-orange {
    background-color: $color-orange;
    &:focus,
    &:hover {
      background-color: $color-orangeHover;
    }
    &:active {
      background-color: $color-orangeActive;
    }
    &.disabled {
      background-color: $color-orangeHover;
      &:active,
      &:hover {
        background-color: $color-orangeHover;
      }
    }
  }
  &.g_btn-red {
    background-color: $color-red;
    &:focus,
    &:hover {
      background-color: $color-redHover;
    }
    &:active {
      background-color: $color-redActive;
    }
    &.disabled {
      &:active,
      &:hover {
        background-color: $color-grey1;
      }
    }
  }
  &.g_btn-black {
    background-color: $color-black1;
    color: $color-white;

    &:focus,
    &:hover {
      background-color: $color-black5;
      color: $color-white;
    }
    &:active {
      background-color: $color-black1;
      color: $color-white;
    }
    &.disabled {
      &:active,
      &:hover {
        background-color: $color-black1;
        color: $color-white;
      }
    }
  }

  &.g_btn-whiteTrans {
    background-color: $color-unnamed3;
    color: $color-grey3;
    &:hover,
    &:active,
    &:hover {
      background-color: $color-unnamed12;
    }
  }

  &.g_btn-blackTrans {
    background-color: $color-unnamed10;
    color: $color-white;
    &:hover,
    &:active,
    &:hover {
      background-color: $color-unnamed8;
    }
  }
}

// icons on buttons

.g_btn {
  &.g_btn-download {
    background-repeat: no-repeat;
    background-image: url('/node-static/img/nav-download.svg');
    background-size: 16px;
    margin-left: 0;

    padding-left: 60px;
    width: auto;
    height: inherit;
    background-position: 25px center;
    text-align: center;
  }
  &.g_btn-addToCart {
    width: 36px;
    height: 32px;
    background-image: url('/node-static/img/nav-download.svg');
    background-position: center right 10px;
    background-size: 18px;
    background-repeat: no-repeat;
  }
  &.g_btn-search {
    max-height: inherit;
    padding: 5px 12px 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-color: transparent;
    background-color: $color-white;
    cursor: auto;
    &.g_btn-search-inPage {
      @media #{$large-up} {
        padding-top: 13px;
        padding-bottom: 11px;
      }
    }

    &.g_btn-search-inactive {
      background-color: $color-unnamed12;
    }
  }
  &.g_btn-checkmark {
    width: 32px;
    height: 32px;
    background-image: url('/node-static/img/check.svg');
    background-position: center;
    background-size: 20px;
  }
  &.g_btn-checkmark-left {
    padding-left: 40px;
    background-image: url('/node-static/img/check.svg');
    background-position: 16px center;
    background-size: 16px;
    background-repeat: no-repeat;
  }
  &.g_btn-spinner {
    overflow: hidden;
    &:before {
      display: inline-block;
      margin-bottom: -2px;

      width: 16px;
      height: 16px;
      background-image: url('/node-static/img/spinner-32-white.png');
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      content: '';
      animation: spin 0.7s linear infinite;
    }
    &.g_btn-spinner_withText:before {
      margin-right: 12px;
    }
  }
}

// used in svgs only,
.g_btn_icon-red {
  fill: $color-red;
}

.g_btn_icon-checkout {
  display: inline-block;
  margin-right: 8px !important;

  width: 16px;
  height: 16px;
  background-image: url('/node-static/img/cart.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: text-bottom;
}

.g_buttonSpacing {
  margin-top: 20px;
  margin-right: 20px;

  @media #{$medium-up} {
    margin-top: 24px;
    margin-right: 24px;
  }

  @media #{$large-up} {
    margin-top: 32px;
  }

  @media #{$xlarge-up} {
    margin-top: 40px;
  }
  &:last-child {
    margin-right: 0;
  }

  &.g_buttonSpacing-small {
    margin-top: 12px;
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
}

// used on actionbars
.g_buttonSpacing_left {
  margin-left: 8px;
}
