// REGULAR CASES:
// The Typo values are the base for the *regular* bottom margins, line-heights and font-sizes of the respective elements
// margin-bottom: $typoValue; (except for typo_headline where it is $typoValue/2)
// font-size: $typoValue * 2 - 8;
// line-height: $typoValue * 2;

/* EXCEPTIONS:
 * - typo_headline has a margin of $typoValue/2
 * - the margin-bottom rule does not always apply to .typo_pStatic
 * - buttons, tables, icons and images have a fixed margin defined for each breakpoint (see below)
 * - tablePrice does not have a bottom margin
 */

$typo-headline-small: 16px;
$typo-headline-medium: 20px;
$typo-headline-large: 24px;
$typo-headline-xlarge: 32px;

.typo_headline {
  // typo_h2
  display: block;
  @include font-regular();
  @include font-size-typovalue($typo-headline-small);
  color: $color-black5;
  fill: $color-black5;
  letter-spacing: 0.3px;
  margin: 0;
  margin-bottom: $typo-headline-small/2;

  &:last-child {
    margin-bottom: 0;
  }

  @media #{$medium-up} {
    @include font-size-typovalue($typo-headline-medium);
    margin-bottom: $typo-headline-medium/2;
  }
  @media #{$large-up} {
    @include font-size-typovalue($typo-headline-large);
    margin-bottom: $typo-headline-large/2;
  }
  @media #{$xlarge-up} {
    letter-spacing: 0.4px;
    @include font-size-typovalue($typo-headline-xlarge);
    margin-bottom: $typo-headline-xlarge/2;
  }
  &.typo_headline-exceptionsForMissions {
    @include font-bold();
    @include font-size(16);
    @media #{$medium-up} {
      @include font-size(32);
    }
    @media #{$large-up} {
      font-size: inherit;
      line-height: inherit;
    }
  }
}

$typo-headline2-small: 14px;
$typo-headline2-medium: 16px;
$typo-headline2-large: 20px;
$typo-headline2-xlarge: 24px;

.typo_headline2 {
  // typo_h3
  display: block;
  @include font-bold();
  @include font-size-typovalue($typo-headline2-small);
  color: $color-black5;
  fill: $color-black5;
  letter-spacing: 0.3px;
  margin: 0 0 $typo-headline2-small 0;
  &:last-child {
    margin-bottom: 0;
  }
  @media #{$medium-up} {
    @include font-size-typovalue($typo-headline2-medium);
    margin-bottom: $typo-headline2-medium;
  }
  @media #{$large-up} {
    @include font-size-typovalue($typo-headline2-large);
    margin-bottom: $typo-headline2-large;
  }
  @media #{$xlarge-up} {
    @include font-size-typovalue($typo-headline2-xlarge);
    margin-bottom: $typo-headline2-xlarge;
  }
}

$typo-headline3-small: 13px;
$typo-headline3-medium: 15px;
$typo-headline3-large: 18px;
$typo-headline3-xlarge: 20px;

.typo_headline3 {
  // typo_h4
  display: block;
  @include font-regular();
  @include font-size-typovalue($typo-headline3-small);
  color: $color-black5;
  fill: $color-black5;
  letter-spacing: 0.3px;
  margin: 0 0 $typo-headline3-small 0;
  &:last-child {
    margin-bottom: 0;
  }
  @media #{$medium-up} {
    @include font-size-typovalue($typo-headline3-medium);
    margin-bottom: $typo-headline3-medium;
  }
  @media #{$large-up} {
    @include font-size-typovalue($typo-headline3-large);
    margin-bottom: $typo-headline3-large;
  }
  @media #{$xlarge-up} {
    @include font-size-typovalue($typo-headline3-xlarge);
    margin-bottom: $typo-headline3-xlarge;
  }
}

$typo-headline4-small: 14px;
$typo-headline4-large: 16px;

.typo_headline4 {
  display: block;
  @include font-regular();
  @include font-size-typovalue($typo-headline4-small);
  color: $color-black5;
  fill: $color-black5;
  letter-spacing: 0.3px;
  margin: 0 0 $typo-headline4-small 0;
  &:last-child {
    margin-bottom: 0;
  }
  @media #{$large-up} {
    @include font-size-typovalue($typo-headline4-large);
    margin-bottom: $typo-headline4-large;
  }
  &.typo_headline4-bold {
    @include font-bold();
  }
}

$typo-sub1-small: 12px;
$typo-sub1-medium: 14px;
$typo-sub1-large: 16px;

$typo-sub2-small: 14px;
$typo-sub2-medium: 16px;

.typo_sub2 {
  display: block;
  @include font-bold();
  @include font-size-typovalue($typo-sub2-small);
  margin: 0 0 $typo-sub2-small 0;
  color: $color-black5;
  fill: $color-black5;
  letter-spacing: 0;
  &:last-child {
    margin-bottom: 0;
  }
  @media #{$medium-up} {
    @include font-size-typovalue($typo-sub2-medium);
    margin-bottom: $typo-sub2-medium;
  }
}

$typo-subHeader-small: 10px;
$typo-subHeader-large: 11px;

.typo_subHeader {
  display: block;
  @include font-regular();
  @include font-size-typovalue($typo-subHeader-small);
  margin: 0 0 $typo-subHeader-small 0;
  color: $color-black5;
  fill: $color-black5;
  letter-spacing: 0;
  &:last-child {
    margin-bottom: 0;
  }
  @media #{$large-up} {
    @include font-size-typovalue($typo-subHeader-large);
    margin-bottom: $typo-subHeader-large;
  }
  &.typo_subHeader-bold {
    @include font-bold();
  }
}

$typo-p-small: 11px;
$typo-p-medium: 12px;

.typo_p {
  display: block;
  @include font-regular();
  @include font-size-typovalue($typo-p-small);
  color: $color-black5;
  fill: $color-black5;
  letter-spacing: 0.4px;
  margin: 0 0 $typo-p-small 0;
  &:last-child {
    margin-bottom: 0;
  }
  @media #{$medium-up} {
    @include font-size-typovalue($typo-p-medium);
    margin-bottom: $typo-p-medium;
  }
  &.typo_p-topMargin {
    margin-top: 24px;
  }
  &.typo_p-bold {
    @include font-bold();
  }
}

$typo-pStatic-small: 11px;

.typo_pStatic {
  display: block;
  @include font-regular();
  @include font-size-typovalue($typo-pStatic-small);
  color: $color-black5;
  fill: $color-black5;
  letter-spacing: 0;
  margin: 0 0 $typo-pStatic-small 0;
  &:last-child {
    margin-bottom: 0;
  }
  &.typo_pStatic-bold {
    @include font-bold();
  }
  &.typo_pStatic-largeMargin {
    margin-bottom: 22px;
  }
}

$typo-pMin-small: 10px;

.typo_pMin {
  display: block;
  @include font-regular();
  @include font-size-typovalue($typo-pMin-small);
  color: $color-black5;
  fill: $color-black5;
  letter-spacing: 0;
  margin: 0 0 $typo-pMin-small 0;
  &:last-child {
    margin-bottom: 0;
  }
}

$typo-cap-small: 10px;
$typo-cap-medium: 11px;

.typo_cap {
  display: block;
  @include font-regular();
  @include font-size-typovalue($typo-cap-small);
  margin: 0 0 $typo-cap-small 0;
  color: $color-black5;
  fill: $color-black5;
  &:last-child {
    margin-bottom: 0;
  }
  @media #{$medium-up} {
    @include font-size-typovalue($typo-cap-medium);
    margin-bottom: $typo-cap-medium;
  }
  &.typo_cap-bold {
    @include font-bold();
  }
}

$typo-p-table-small: 11px;
$typo-p-table-xlarge: 12px;

.typo_tableP {
  display: block;
  margin: 0 0 $typo-p-table-small 0;
  @include font-regular();
  @include font-size-typovalue($typo-p-table-small);
  color: $color-black5;
  fill: $color-black5;
  letter-spacing: 0.3px;
  &:last-child {
    margin-bottom: 0;
  }
  @media #{$xlarge-up} {
    margin: 0 0 $typo-p-table-xlarge 0;
    @include font-size-typovalue($typo-p-table-xlarge);
  }
  &.typo_tableP-exceptionsForMissions {
    @include font-size(12);
    @media #{$medium-up} {
      font-size: inherit;
      line-height: inherit;
    }
  }
}

//footer links
$typo-footerSection-small: 12px;

.typo_footerSection {
  display: block;
  @include font-regular();
  @include font-size-typovalue($typo-footerSection-small);
  letter-spacing: 0.2px;
  margin: 0;
  margin-bottom: $typo-footerSection-small;
  color: $color-black5;
  fill: $color-black5;
  &:last-child {
    margin-bottom: 0;
  }
  &.typo_footerSection-bold {
    @include font-bold();
  }

  &.typo_footerSection-onDark {
    // used on mission landding
    color: $color-white;
    fill: $color-white;
  }
}

$typo-footerSub-small: 9px;

.typo_footerSub {
  display: block;
  @include font-regular();
  @include font-size-typovalue($typo-footerSub-small);
  color: $color-black5;
  fill: $color-black5;
  letter-spacing: 0.1px;
  margin: 0;
  margin-bottom: $typo-footerSub-small;

  &:last-child {
    margin-bottom: 0;
  }
  &.typo_footerSub-copyright {
    padding-top: 16px;
    padding-bottom: 14px;

    border-bottom: 1px solid $color-grey4;
    @media #{$xlarge-up} {
      padding-top: 12px;
      padding-bottom: 8px;
      border: none;
    }
  }
}

$typo-tablePrice-small: 24px;
$typo-tablePrice-medium: 14px;

// EXCEPTIONS:
// Some elements like buttons or tables have a fixed top-margin that overwrite the *regular* bottom-margin of the previous element.
// To do this, we calculate the top-margin by subtracting the *regular* bottom-margin of the previous sibling from the fixed top-margin.
// Negative Top Margins may occur.

// Exception 1: Buttons
$fixedTop-btn-small: 20px;
$fixedTop-btn-medium: 24px;
$fixedTop-btn-large: 32px;
$fixedTop-btn-xlarge: 40px;

.typo_headline + .g_btn,
.typo_headline + .typo_wrapper > .g_btn:first-child {
  margin-top: $fixedTop-btn-small - $typo-headline-small;
}

.typo_headline2 + .g_btn,
.typo_headline2 + .typo_wrapper > .g_btn:first-child {
  margin-top: $fixedTop-btn-small - $typo-headline2-small;
}

.typo_headline3 + .g_btn,
.typo_headline3 + .typo_wrapper > .g_btn:first-child {
  margin-top: $fixedTop-btn-small - $typo-headline3-small;
}

.typo_headline4 + .g_btn,
.typo_headline4 + .typo_wrapper > .g_btn:first-child {
  margin-top: $fixedTop-btn-small - $typo-headline4-small;
}

.typo_sub2 + .g_btn,
.typo_sub2 + .typo_wrapper > .g_btn:first-child {
  margin-top: $fixedTop-btn-small - $typo-sub2-small;
}

.typo_subHeader + .g_btn,
.typo_subHeader + .typo_wrapper > .g_btn:first-child {
  margin-top: $fixedTop-btn-small - $typo-subHeader-small;
}

.typo_p + .g_btn,
.typo_p + .typo_wrapper > .g_btn:first-child {
  margin-top: $fixedTop-btn-small - $typo-p-small;
}

.typo_cap + .g_btn,
.typo_cap + .typo_wrapper > .g_btn:first-child {
  margin-top: $fixedTop-btn-small - $typo-cap-small;
}

.typo_pStatic + .g_btn,
.typo_pStatic + .typo_wrapper > .g_btn:first-child {
  margin-top: $fixedTop-btn-small - $typo-pStatic-small;
}

.typo_pMin + .g_btn,
.typo_pMin + .typo_wrapper > .g_btn:first-child {
  margin-top: $fixedTop-btn-small - $typo-pMin-small;
}

@media #{$medium-up} {
  .typo_headline + .g_btn,
  .typo_headline + .typo_wrapper > .g_btn:first-child {
    margin-top: $fixedTop-btn-medium - $typo-headline-medium;
  }

  .typo_headline2 + .g_btn,
  .typo_headline2 + .typo_wrapper > .g_btn:first-child {
    margin-top: $fixedTop-btn-medium - $typo-headline2-medium;
  }

  .typo_headline3 + .g_btn,
  .typo_headline3 + .typo_wrapper > .g_btn:first-child {
    margin-top: $fixedTop-btn-medium - $typo-headline3-medium;
  }

  .typo_p + .g_btn,
  .typo_p + .typo_wrapper > .g_btn:first-child {
    margin-top: $fixedTop-btn-medium - $typo-p-medium;
  }

  .typo_cap + .g_btn,
  .typo_cap + .typo_wrapper > .g_btn:first-child {
    margin-top: $fixedTop-btn-medium - $typo-cap-medium;
  }

  .typo_pStatic + .g_btn,
  .typo_pStatic + .typo_wrapper > .g_btn:first-child {
    margin-top: $fixedTop-btn-medium - $typo-pStatic-small;
  }
}

@media #{$large-up} {
  .typo_headline + .g_btn,
  .typo_headline + .typo_wrapper > .g_btn:first-child {
    margin-top: $fixedTop-btn-large - $typo-headline-large;
  }

  .typo_headline2 + .g_btn,
  .typo_headline2 + .typo_wrapper > .g_btn:first-child {
    margin-top: $fixedTop-btn-large - $typo-headline2-large;
  }

  .typo_headline3 + .g_btn,
  .typo_headline3 + .typo_wrapper > .g_btn:first-child {
    margin-top: $fixedTop-btn-large - $typo-headline3-large;
  }

  .typo_headline4 + .g_btn,
  .typo_headline4 + .typo_wrapper > .g_btn:first-child {
    margin-top: $fixedTop-btn-large - $typo-headline4-large;
  }

  .typo_pStatic + .g_btn,
  .typo_pStatic + .typo_wrapper > .g_btn:first-child {
    margin-top: $fixedTop-btn-large - $typo-pStatic-small;
  }

  .typo_subHeader + .g_btn,
  .typo_subHeader + .typo_wrapper > .g_btn:first-child {
    margin-top: $fixedTop-btn-large - $typo-subHeader-large;
  }

  .typo_p + .g_btn,
  .typo_p + .typo_wrapper > .g_btn:first-child {
    // p doesn't have specific sizes for >medium
    margin-top: $fixedTop-btn-large - $typo-p-medium;
  }
}

@media #{$xlarge-up} {
  .typo_headline + .g_btn,
  .typo_headline + .typo_wrapper > .g_btn:first-child {
    margin-top: $fixedTop-btn-xlarge - $typo-headline-xlarge;
  }

  .typo_headline2 + .g_btn,
  .typo_headline2 + .typo_wrapper > .g_btn:first-child {
    margin-top: $fixedTop-btn-xlarge - $typo-headline2-xlarge;
  }

  .typo_headline3 + .g_btn,
  .typo_headline3 + .typo_wrapper > .g_btn:first-child {
    margin-top: $fixedTop-btn-xlarge - $typo-headline3-xlarge;
  }

  .typo_pStatic + .g_btn,
  .typo_pStatic + .typo_wrapper > .g_btn:first-child {
    margin-top: $fixedTop-btn-xlarge - $typo-pStatic-small;
  }

  .typo_subHeader + .g_btn,
  .typo_subHeader + .typo_wrapper > .g_btn:first-child {
    // subHeader doesn't have specific sizes for >large
    margin-top: $fixedTop-btn-xlarge - $typo-subHeader-large;
  }

  .typo_p + .g_btn,
  .typo_p + .typo_wrapper > .g_btn:first-child {
    // p doesn't have specific sizes for >medium
    margin-top: $fixedTop-btn-large - $typo-p-medium;
  }
}

// Exception 2: Images
$fixedTop-img-small: 24px;
$fixedTop-img-medium: 32px;
$fixedTop-img-large: 40px;

.typo_img {
  display: inline-block;
  margin-bottom: 24px;
  @media #{$medium-up} {
    margin-bottom: 32px;
  }
  @media #{$large-up} {
    margin-bottom: 40px;
  }
  margin-top: $fixedTop-img-small;

  &:first-child {
    margin-top: 0;
  }
  .typo_wrapper &:first-child {
    margin-top: $fixedTop-img-small;
  }
  &.typo_img-mediumUp {
    display: none;
    @media #{$medium-up} {
      display: inline-block;
    }
  }
}

.typo_img + .typo_img {
  margin-top: $fixedTop-img-small;
}
.typo_headline + .typo_img {
  margin-top: $fixedTop-img-small - $typo-headline-small;
}
.typo_headline2 + .typo_img {
  margin-top: $fixedTop-img-small - $typo-headline2-small;
}
.typo_headline3 + .typo_img {
  margin-top: $fixedTop-img-small - $typo-headline3-small;
}
.typo_headline4 + .typo_img {
  margin-top: $fixedTop-img-small - $typo-headline4-small;
}
.typo_sub2 + .typo_img {
  margin-top: $fixedTop-img-small - $typo-sub2-small;
}
.typo_subHeader + .typo_img {
  margin-top: $fixedTop-img-small - $typo-subHeader-small;
}
.typo_p + .typo_img {
  margin-top: $fixedTop-img-small - $typo-p-small;
}
.typo_cap + .typo_img {
  margin-top: $fixedTop-img-small - $typo-cap-small;
}
.typo_pStatic + .typo_img {
  margin-top: $fixedTop-img-small - $typo-pStatic-small;
}
.typo_pMin + .typo_img {
  margin-top: $fixedTop-img-small - $typo-pMin-small;
}

@media #{$medium-up} {
  .typo_img + .typo_img {
    margin-top: $fixedTop-img-medium;
  }
  .typo_headline + .typo_img {
    margin-top: $fixedTop-img-medium - $typo-headline-medium;
  }
  .typo_headline2 + .typo_img {
    margin-top: $fixedTop-img-medium - $typo-headline2-medium;
  }
  .typo_headline3 + .typo_img {
    margin-top: $fixedTop-img-medium - $typo-headline3-medium;
  }
  .typo_p + .typo_img {
    margin-top: $fixedTop-img-medium - $typo-p-medium;
  }
  .typo_cap + .typo_img {
    margin-top: $fixedTop-img-medium - $typo-cap-medium;
  }
}

@media #{$large-up} {
  .typo_img + .typo_img {
    margin-top: $fixedTop-img-large;
  }
  .typo_headline + .typo_img {
    margin-top: $fixedTop-img-large - $typo-headline-large;
  }
  .typo_headline2 + .typo_img {
    margin-top: $fixedTop-img-large - $typo-headline2-large;
  }
  .typo_headline3 + .typo_img {
    margin-top: $fixedTop-img-large - $typo-headline3-large;
  }
  .typo_headline4 + .typo_img {
    margin-top: $fixedTop-img-large - $typo-headline4-large;
  }
  .typo_subHeader + .typo_img {
    margin-top: $fixedTop-img-large - $typo-subHeader-large;
  }
  .typo_p + .typo_img {
    margin-top: $fixedTop-img-large - $typo-p-medium; // no typo, there's no special case for large on typo_p's
  }
  .typo_cap + .typo_img {
    margin-top: $fixedTop-img-large - $typo-cap-medium; // no typo, there's no special case for large on typo_cap's
  }
}

// Exception 4: Tables
$fixedTop-table-small: 32px;
$fixedTop-table-medium: 48px;
$fixedTop-table-large: 64px;
$fixedTop-table-xlarge: 80px;

.typo_sub2 + .typo_img {
  margin-top: $fixedTop-table-small - $typo-sub2-small;
}
.typo_pStatic + .typo_img {
  margin-top: $fixedTop-table-small - $typo-pStatic-small;
}
.typo_pMin + .typo_img {
  margin-top: $fixedTop-table-small - $typo-pMin-small;
}

// for rare cases, a top margin boost can be added to an element.
// it only makes sense for elements that don't have a fixed top-margin
// and it gets added to the bottom-margin of the previous sibling
.typo-topMarginBoost {
  margin-top: 36px;
  @media #{$medium-up} {
    margin-top: 56px;
  }
}

.typo-topMarginBoost-xsmall {
  margin-top: 4px;
  @media #{$xlarge-up} {
    margin-top: 8px;
  }
}

.typo-topMarginBoost-xsmall2 {
  @media #{$large-up} {
    margin-top: 2px;
  }
  @media #{$xlarge-up} {
    margin-top: 4px;
  }
}

.typo-topMarginBoost-small {
  margin-top: 16px;
  @media #{$medium-up} {
    margin-top: 24px;
  }
}

.typo-topMarginBoost-smallStatic {
  margin-top: 16px;
}

.typo-topMarginBoost-small2 {
  margin-top: 12px;
}

.typo-topMarginBoost-medium {
  margin-top: 45px;
  margin-bottom: 0;
  @media #{$medium-up} {
    margin-top: 68px;
  }
  @media #{$large-up} {
    margin-top: 76px;
  }
}

.typo-topMarginBoost-large {
  margin-top: 48px;
  margin-bottom: 0;
}

.typo-topMarginBoost-large2 {
  margin-top: 40px;
}

.typo-topMarginBoost-xlarge {
  margin-top: 104px;
}

.typo-color-white {
  color: $color-white;
  fill: $color-white;
}

.typo-color-dark {
  color: $color-black5;
  fill: $color-black5;
}

.typo-color-grey2 {
  color: $color-grey2;
  fill: $color-grey2;
}

.typo-color-grey3 {
  color: $color-grey3;
  fill: $color-grey3;
}

.typo-noMargin {
  margin-bottom: 0;
}

.typo-inlineBlock {
  display: inline-block;
}

.typo-inline {
  display: inline;
}

.typo_wrapper {
  // to prevent border collapsing we need to have a padding
  padding: 0.1px;
}
