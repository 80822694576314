.photoCard {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-bottom: 20px;

  width: 100%;
  @media #{$medium-up} {
    margin-bottom: 20px;
  }
  a {
    text-decoration: none;
  }
  .photoCard_photo {
    position: absolute;
    z-index: $zPhotoCardImage;
    display: block;

    width: 100%;
    height: auto;

    &:before {
      visibility: hidden;
    }
  }
  img {
    max-width: 100%;
  }
  &.photoCard-releases {
    border-radius: 2px;
    box-shadow: 0 0 4px 1px $color-unnamed16;
  }
}

.photoCard_photoIndex {
  @include font-size(10, 21);

  z-index: $photocardFeedbackTrigger;
  position: absolute;
  top: 3px;
  left: 3px;
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  border: 1px solid $color-black1;
  background-color: $color-white;
}

.photoCard_releasesNote {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zPhotoCardReleasesNote;

  padding: 20px;
  width: 100%;
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: linear-gradient(to bottom, $color-unnamed9 0%, transparent 100%);
  color: $color-white;
  pointer-events: none;
}

.photoCard_releasesNote_badge {
  margin-right: 10px !important;
  padding: 2px 9px;
  min-width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: $color-white;
}

.photoCard_heightPhantom {
  position: relative;
  width: 100%;
  overflow: hidden;
  // covers rounding errors, i'm sorry
  bottom: -0.2px;

  &:after {
    width: 100%;
    position: absolute;
    height: 100%;
    content: ' ';
    top: 0;
    left: 0;
    background-color: #0f0f11;
    background-image: url('/node-static/img/gridplaceholder-grey.svg');
    background-position: center center;
    background-repeat: no-repeat;
  }
}

.photoCard_addToCart-wrapper {
  position: relative;
  display: inline-block;
}

.photoCard_labelsWrapper {
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.64) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  left: 0;
  right: 0;
  z-index: 2;
  bottom: 0;

  .photoCard-captioned & {
    bottom: 28px;
  }
}

.photoCard_caption {
  width: 100%;

  padding-top: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: lowercase;
  &:first-letter {
    text-transform: capitalize;
  }
}
