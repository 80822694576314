// TODO: clean this up and move to styled-components

.blacklistCheck {
  z-index: 0;

  width: 100%;
  .photo {
    z-index: $zBlacklistCheckPhoto;
  }

  .blacklistCheck_outer {
    position: absolute;
    z-index: $zBlacklistCheck;
    display: block;

    width: 100%;
    color: $color-white;
    cursor: pointer;

    &:before {
      display: inline-block;

      height: 100%;
      content: '';
      vertical-align: middle;
    }
  }
  .blacklistCheck_inner {
    display: inline-block;

    width: 100%;
    vertical-align: middle;
    text-align: center;
  }
  .blacklistCheck_message {
    margin-top: 16px;
    margin-bottom: 6px;

    line-height: 24px;
  }
  .blacklistCheck_subtitle {
    margin-top: 0;
    margin-bottom: 0;

    opacity: 0.4;
  }
}

.photoCard {
  .blacklistCheck_outer {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: $color-black5;
  }
  .blacklistCheck_mask {
    opacity: 0.4;
  }
}
.pview_stage {
  .blacklistCheck {
    position: relative;

    height: 100%;

    .blacklistCheck_outer {
      height: 100%;
      @media #{$medium-up} {
        top: 0;
        bottom: 24px;
        left: 50%;
        margin-left: -250px;

        width: 500px;
        height: auto;
        background-color: $color-black5;
      }
    }
  }

  .blacklistCheck-contextBelt {
    position: absolute;
    bottom: 8px;
    left: calc(50% - 51px);

    width: 90px;
    height: 100px;
    background-color: $color-black5;
  }
}
