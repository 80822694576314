.nb {
  position: fixed;
  z-index: $zNotificationBar;
  overflow: hidden;
  left: 0;
  right: 0;
  .nb_close {
    @include opacity(60);

    position: absolute;
    top: 24px;
    right: 24px;

    width: 14px;
    height: 14px;
    color: $color-white;
    cursor: pointer;

    &:hover {
      @include opacity(100);
    }
  }
  &.nb-underNav {
    position: relative;
    z-index: $zNavbar - 1;
    .eyecon-32-upload {
      @include font-size(24, 16);
      padding-right: 4px;
      vertical-align: text-bottom;
    }
  }
  .nb-bold {
    @include font-bold();
  }
}

.nb_h2 {
  @include font-size(16, 24);

  margin: 0;

  padding: 20px 56px 20px 32px;
  color: $color-white;
}

.nb-success {
  background-color: $color-aqua;
}

.nb-almostSuccess {
  background: repeating-linear-gradient(
    -45deg,
    $color-aqua,
    $color-aqua 250px,
    $color-orange 250px,
    $color-orange 500px
  );
}

.nb-warning {
  background-color: $color-orange;
}

.nb-error {
  background-color: $color-red;
}
