// used for all transitions in the justified imagegrid
$transition-timing: 0.3s;

.simpleJustified_imageBox {
  overflow: hidden;
  display: inline-block;
  position: relative;
  .simpleJustified_imageBox_hoverBox {
    opacity: 0;
    bottom: -24px;
  }
  &:hover .simpleJustified_imageBox_hoverBox {
    opacity: 1;
    bottom: 0;
  }
}

.missionGridItem {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;

  &:hover {
    .missionGridItem_background {
      opacity: 0.4;
    }
    @media #{$xlarge-up} {
      .missionGridItem_logoContainer-primary {
        opacity: 0;
        transition: opacity $transition-timing ease-in-out;
      }

      .missionGridItem_logoContainer-secondary {
        opacity: 1;
        transition: opacity $transition-timing ease-in-out $transition-timing;
      }
    }
  }
}

.missionGridItem_logoContainer-primary,
.missionGridItem_logoContainer-secondary {
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 16px;
  left: 16px;
  right: 16px;
  bottom: 16px;
}

.missionGridItem_logoContainer-primary {
  opacity: 1;
  transition: opacity $transition-timing ease-in-out $transition-timing;
}

.missionGridItem_logoContainer-secondary {
  opacity: 0;
  top: 60%;
  transition: opacity ease-in-out $transition-timing;
  transform: scale(0.5, 0.5);
}

.missionGridItem_background {
  position: absolute;
  background: black;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
  z-index: 1;
  transition: $transition-timing;
}
