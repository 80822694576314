// MAGIC NUMBER EXPLANATION: up till size large the max-width of landing page slides
// is defined by paddings on the side, at the maximum point of size large, the width is 1024-24-24=976px.
// Sticky headers are positioned using margins (just padding the content doesn't work because of the bottom border)
// However, to center then on xlarge-up, we need margin:auto. Building this is easiest, when the max-width of the element
// is just as wide as the element is at the moment margin:auto kicks in: 976px;
$lp_max_width: 976px;

.hr-landing {
  border: none;
  height: 1px;
  background: $color-grey5;
  margin: 36px 0 18px 0;
  @media #{$medium-up} {
    margin: 56px 0 28px 0;
  }
}

.landing_sections {
  position: relative;
  z-index: $z-landing-sections;
}

.landing_sections_bottomBox {
  display: block;
  padding: 0 0 20px 0;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  @media #{$medium-up} {
    padding: 32px 0 12px 0;
    background-color: $color-unnamed10;
    &.landing_sections_bottomBox-noSubline {
      padding: 32px 0;
    }
  }
}

.lp_fullWidth {
  margin-left: 24px;
  margin-right: 24px;
  @media #{$medium-up} {
    margin-left: 32px;
    margin-right: 32px;
  }
  @media #{$large-up} {
    margin-left: 40px;
    margin-right: 40px;
  }
}

// only used on bcg mlp
.webflow_maxWidth {
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1200px;
  margin: auto;
  position: relative;
  @media only screen and (max-width: 991px) {
    padding-left: 0;
    padding-right: 0;
    max-width: 555px;
    margin: auto;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 54px;
    padding-right: 54px;
  }
  @media only screen and (max-width: 479px) {
    padding-left: 24px;
    padding-right: 24px;
  }
}
.lp_maxWidth {
  padding-left: 24px;
  padding-right: 24px;
  &.lp_maxWidth-smallFullWidth {
    padding-left: 0;
    padding-right: 0;

    @media #{$medium-up} {
      padding-left: 32px;
      padding-right: 32px;
    }
    @media #{$xlarge-up} {
      padding-left: 0;
      padding-right: 0;
      max-width: $lp_max_width;
      margin: auto;
    }
  }
  @media #{$medium-up} {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media #{$xlarge-up} {
    padding-left: 0;
    padding-right: 0;
    max-width: $lp_max_width;
    margin: auto;
  }
}

.lp_sectionPadding {
  padding-top: 56px;
  padding-bottom: 56px;
  @media #{$medium-up} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media #{$large-up} {
    padding-top: 88px;
    padding-bottom: 88px;
  }
  @media #{$xlarge-up} {
    padding-top: 96px;
    padding-bottom: 96px;
  }
  &.lp_sectionPadding-bordered {
    border-top: 1px solid $color-grey5;
  }
  &.lp_sectionPadding-noTop {
    padding-top: 0;
  }
  &.lp_sectionPadding-noBottom {
    padding-bottom: 0;
  }
}

.landingQuote_q {
  quotes: none;
}
.landingQuote_cite {
  font-style: normal;
}
.landingQuote_blockquote {
  margin: 0;
  padding: 36px 20px;
  @media #{$medium-up} {
    padding: 96px 24px;
  }
  @media #{$xlarge-up} {
    padding: 96px 0;
    max-width: $lp_max_width;
    margin: auto;
  }
}
.landingQuote_blockquote-small {
  padding: 36px 0;
  @media #{$medium-up} {
    padding: 64px 0;
  }
}

//landing section
.ls {
  padding: 36px 20px;
  overflow: hidden;
  background-color: $color-white;
  position: relative;
  @include display-flex();

  //vertically align
  > div {
    margin: auto;

    max-width: $lp_max_width;
  }
  @media #{$medium-up} {
    min-height: 656px;
    padding: 96px 24px;
  }
  @media #{$large-up} {
    min-height: 615px;
  }
  @media #{$xlarge-up} {
    min-height: 540px;
  }

  &.ls-no-padding {
    padding: 0;
  }
  &.ls-dark {
    background: $color-black5;
  }

  &.ls-flex-vertical {
    flex-direction: column;
  }

  &.ls-no-flex {
    display: block;
  }
}

.imageBreak_credit {
  @include font-regular();
  @include font-size(10, 14);

  position: absolute;
  bottom: 16px;
  z-index: $z-landing-scroll;

  color: $color-white;

  text-decoration: none;

  @media #{$medium-up} {
    @include font-size(14, 14);
  }

  left: 20px;
  @media #{$large-up} {
    left: 24px;
  }
  @media #{$xlarge-up} {
    left: 32px;
  }
}

.imageBreak_bottomLeft {
  display: none;
  @media #{$medium-up} {
    display: block;
    position: absolute;
    left: 32px;
    bottom: 16px;
  }
}

.imageBreak_bottomRight {
  display: none;
  @media #{$medium-up} {
    display: block;
    position: absolute;
    right: 32px;
    bottom: 32px;
  }
}

.imageBreak_bottomCenter {
  display: none;
  @media #{$medium-up} {
    display: block;
    position: absolute;
    bottom: 32px;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
  }
}

.standaloneSearchInput {
  display: block;
  background: $color-black2;
  padding-top: 4px;
  padding-bottom: 12px;
}
