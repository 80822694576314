/**
 * Grid styles
 */
.grid-topMargin {
  margin-top: 20px;
  @media #{$large-up} {
    margin-top: 24px;
  }
  @media #{$large-up} {
    margin-top: 32px;
  }
}

.grid_topBar {
  @include clearfix;
  padding: 12px;
  @media #{$medium-up} {
    padding: 16px;
  }
  @media #{$large-up} {
    padding: 20px;
  }
}

.userGrid {
  margin-left: calc(20px - 10px);
  margin-right: calc(20px - 10px);
  @media #{$medium-up} {
    margin-left: calc(24px - 10px);
    margin-right: calc(24px - 10px);
  }
  @media #{$large-up} {
    margin-left: calc(32px - 10px);
    margin-right: calc(32px - 10px);
  }

  .column,
  .columns {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.empty-grid {
  margin-bottom: 80px;
}

.pgrid_endOfStream {
  padding: 64px 0;

  &.spinner {
    position: relative;
    top: -104px;
    z-index: 3;
    overflow: inherit;

    padding: 0;
    height: 88px;
  }

  img {
    margin: 24px 0 32px;
  }
  .pgrid_endOfStream_button {
    margin: 24px 0 32px;
  }
}

.emptyStateIcon {
  margin: 36px 0 12px 0;
}
