.dwnld_inner {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  h2 {
    @include font-size(32, 'none');
    margin: 0;
    margin-bottom: 8px;
  }
  .g_btn {
    margin: 24px 0px 24px 0px;
  }
}

.dwnld_inner_info {
  margin: 48px 30px;
  text-align: center;

  p {
    @include font-size(14, 22);
    color: $color-grey3;
  }
}

//cw = checkout widget
.cw_container {
  background: white;
  @media #{$medium-up} {
    border-radius: $border-radius-widget;
  }
}
.cw_container-vertical-stack {
  margin-bottom: 16px;
}
.cw_inner {
  padding: 20px;
  @media #{$medium-up} {
    padding: 24px;
  }
}

.cw_empty {
  h2 {
    @include font-regular();
    @include font-size(20, 28);
    margin-top: 40px;
    text-align: center;
    margin-bottom: 12px;
  }
  p {
    margin-bottom: 40px;
    text-align: center;
    @include font-size(14);
    color: $color-unnamed10;
  }
}

.cw_bordered {
  border-top: 1px solid $color-grey5;

  &:first-of-type {
    border-top: 0;
  }
}

//horizontal rule
.hr-cw {
  display: block;
  margin: 16px 0;
  height: 1px;
  background-color: $color-grey5;
}

/*
 * disabled styles for checkout widget
 * preconditions:
 * - empty element with cw_disabled_cover to the end of cw_inner
 * - add cw_disabled to the to be disabled inner container
 */

.cw_disabled {
  position: relative;
  &:after {
    content: '';
    z-index: $z-cw_disabled;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.8;
    background: $color-white;
  }

  input[type='radio'].g_radio:checked + label:before {
    border: 5px solid $color-black5;
  }
}
