.search_sidebar {
  display: block;
  position: static;
  width: 224px;
  float: left;
  padding-left: 24px;
  margin-left: -224px;
  transition: margin-left 0.4s ease, transform 0.4s ease, opacity 0.4s ease;
  opacity: 0;
  &.search_sidebar-visible {
    opacity: 1;
    margin-left: 0;
  }
  @media #{$medium-up} {
    position: absolute;
    float: none;
    bottom: -64px;
    width: 224px;
    padding-left: 32px;
    &.search_sidebar-static {
      position: static;
      float: left;
      top: auto;
      bottom: auto;
      left: auto;
    }
    &.search_sidebar-noResults {
      bottom: 0;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  @media #{$large-up} {
    padding-left: 40px;
  }
}
