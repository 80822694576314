.g_blackTooltip {
  @include font-size(12);

  position: absolute;
  bottom: 40px;
  left: 50%;
  z-index: $z-tooltip;

  padding: 8px 12px;
  border: 1px solid $color-unnamed3;
  border-radius: 3px;
  background-color: $color-black1;
  color: $color-white;
  cursor: pointer;
  /* trigger hwacceleration */
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  -moz-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  transform: translateX(-50%);

  &:before,
  &:after {
    position: absolute;
    left: 50%;
    margin-left: -8px;

    width: 0;
    border-width: 8px 8px 0;
    border-style: solid;
    content: '';
  }

  &:before {
    bottom: -9px;

    border-color: $color-unnamed3 transparent;
  }

  &:after {
    bottom: -7px;

    border-color: $color-black1 transparent;
  }
}
