.g_bubble {
  @include text-overflow();

  padding: 1px 4px;
  border-radius: $border-radius-global;
  background-color: $color-black5;
  color: $color-white;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  @media #{$medium-up} {
    padding: 4px 8px;
  }
  &:hover,
  &:active {
    background-color: $color-black3;
  }
}

.g_followsYou {
  @include font-size(12, 24);

  display: inline-block;
  margin-left: 8px;

  padding: 0 8px;
  border-radius: $border-radius-global;
  background-color: $color-unnamed3;
  color: $color-unnamed28;
  vertical-align: middle;
}
