$anim-timing-quad: cubic-bezier(0.215, 0.61, 0.355, 1);

@keyframes spin {
  from {
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
  to {
    transform: translate3d(0, 0, 0) rotate(360deg);
  }
}

@keyframes cart-item-delete {
  0% {
    max-height: 999px;
    opacity: 1;
    transform: translateZ(0);
  }
  50% {
    max-height: 999px;
    transform: translateZ(0);
  }
  70% {
    opacity: 0;
    transform: translateZ(0);
  }
  100% {
    max-height: 0;
    opacity: 0;
    transform: translateZ(0);
  }
}

@keyframes cart-item-reveal {
  0% {
    max-height: 0;
    opacity: 0;
    transform: translateZ(0);
  }
  50% {
    opacity: 0;
    transform: translateZ(0);
  }
  70% {
    max-height: 999px;
    transform: translateZ(0);
  }
  100% {
    max-height: 999px;
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes action-bar-reveal {
  0% {
    bottom: -48px;
    transform: translateZ(0);
  }
  100% {
    bottom: 0;
    transform: translateZ(0);
  }
}

@keyframes action-bar-hide {
  0% {
    bottom: 0;
    transform: translateZ(0);
  }
  100% {
    bottom: -48px;
    transform: translateZ(0);
  }
}

@keyframes premium-label-pushUp {
  0% {
    transform: translateZ(0) translateY(0);
  }
  100% {
    transform: translateZ(0) translateY(-47px);
  }
}

@keyframes premium-label-pushDown {
  0% {
    transform: translateZ(0) translateY(-47px);
  }
  100% {
    transform: translateZ(0) translateY(0);
  }
}

@keyframes scrollhint {
  from {
    background-position: 0 7px;
    opacity: 0;
  }
  25% {
    background-position: 0 center;
    opacity: 1;
  }
  60% {
    background-position: 0 center;
    opacity: 1;
  }
  80% {
    background-position: 0 32px;
    opacity: 0;
  }
  to {
    background-position: 0 32px;
    opacity: 0;
  }
}

.transition_scroller {
  opacity: 0;
  animation: scrollhint 2s ease-in infinite;

  animation-fill-mode: both;
}

.transition_cartItem-exit {
  animation: cart-item-delete $anim-timing-quad 0.3s;
  //ensure end state is maintained

  animation-fill-mode: forwards;
}

.transition_cartItem-enter {
  position: absolute;

  width: calc(100% - 4px);
  animation: cart-item-reveal $anim-timing-quad 0.3s;
  //ensure end state is maintained

  animation-fill-mode: forwards;
}

.photoCard_labelsWrapper-withActionbar {
  animation: premium-label-pushUp $anim-timing-quad 0.08s;
  //ensure end state is maintained
  animation-fill-mode: forwards;
}

.photoCard_labelsWrapper-withOutActionbar {
  animation: premium-label-pushDown $anim-timing-quad 0.08s;
  //ensure end state is maintained
  animation-fill-mode: forwards;
}

.actionBarTransition-enter {
  animation: action-bar-reveal $anim-timing-quad 0.08s;
  //ensure end state is maintained
  animation-fill-mode: forwards;
}

.actionBarTransition-exit {
  animation: action-bar-hide $anim-timing-quad 0.08s;
  //ensure end state is maintained
  animation-fill-mode: forwards;
}

.actionBarTransition-exit.releaseListItemTransition-exit-active {
  bottom: 0;
}

.releaseListItemTransition-enter {
  max-height: 1px;
  opacity: 0.01;
}

.releaseListItemTransition-enter.releaseListItemTransition-enter-active {
  max-height: 65px;
  opacity: 1;
}

.releaseListItemTransition-exit {
  max-height: 65px;
  opacity: 1;
}

.releaseListItemTransition-exit.releaseListItemTransition-exit-active {
  max-height: 1px;
  opacity: 0.01;
}

/* transition that fades the modal in */
.transition-modal {
  position: relative;
  background-color: $color-unnamed8;

  &.transition-modal-enter {
    // https://hermanote.wordpress.com/2015/11/20/ie11-and-edge-fixed-background-jumpy-glitch/
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zModalOuter;
  }
}

.animation_wiggle {
  transform: translate3d(0, 0, 0);
  animation: wiggle 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;

  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes wiggle {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.headline-appear {
  opacity: 0.01;
}

.headline-appear.headline-appear-active {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

@keyframes shrink-grow-regular {
  25% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulseAndShadow {
  0% {
    transform: none;
    box-shadow: 0 0 0 0 $color-unnamed12;
  }
  20% {
    box-shadow: 0 0 0 0 $color-unnamed12;
  }
  40% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(0.9);
    box-shadow: 0 0 0 16px transparent;
  }
  100% {
    transform: none;
    box-shadow: 0 0 0 0 transparent;
  }
}
