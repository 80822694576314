.releaseManagement_overflowMenu {
  position: absolute;
  top: 32px;
  right: 32px;
}

.releaseManagement_releaseList {
  margin-top: 24px;
}

.releaseManagement_releaseList_item {
  display: block;
  overflow: hidden;
  margin: 0 -32px;

  background-color: $color-white;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: $color-grey7;
  }
  &.releaseManagement_releaseList_item-standalone {
    margin-top: 12px;
    margin-bottom: 12px;
    cursor: default;
    &:hover {
      background-color: $color-white;
    }
  }
  &.releaseManagement_releaseList_item-dark {
    background-color: $color-black4;
    color: $color-white;
    &:hover {
      background-color: $color-black1;
    }
    &.releaseManagement_releaseList_item-standalone {
      background-color: $color-black4;
    }
  }
  &.releaseManagement_releaseList_item-disabled {
    cursor: default;
    &:hover {
      background-color: $color-grey7;
    }
    &.releaseManagement_releaseList_item-dark:hover {
      background-color: $color-black1;
    }
  }
}

.releaseManagement_releaseList_item_inner {
  overflow: hidden;
  margin: 0 30px;

  padding: 12px 0;
  height: 65px;
  border-bottom: 1px solid $color-grey5;
  &.releaseManagement_releaseList_item_inner-noBorder {
    border: none;
  }
}

.releaseManagement_releaseList_item-dark
  .releaseManagement_releaseList_item_inner {
  border-bottom: 1px solid $color-black5;
}
.releaseManagement_releaseList_item_icon {
  margin-right: 8px;

  width: 40px;
  height: 40px;
  border-radius: 24px;
  background-color: $color-white;
  &.releaseManagement_releaseList_item_icon-pending {
    opacity: 0.4;
  }
}

.releaseManagement_releaseList_item_ask {
  padding: 10px 0 0 15px;
}

.releaseManagement_releaseList_item_checkmark {
  margin: 14px 0 0 15px;

  height: 12px;
}
