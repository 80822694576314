.g_flyout {
  @include font-size(12, 'none');

  position: absolute;
  right: 3px;
  bottom: 100%;
  z-index: $zFlyout;
  margin-bottom: 20px;

  border-radius: $border-radius-global;
  box-shadow: 0 0 0 1px $color-unnamed3;
  white-space: nowrap;
  &:before,
  &:after {
    position: absolute;

    width: 0;
    border-style: solid;
    content: '';
  }

  .g_flyout-textOverflow {
    @include text-overflow;
  }

  &.g_flyout-arrowDown-info {
    @include font-size(12, 16);

    top: -75px;
    bottom: inherit;
    left: -20px;

    padding: 6px 12px 9px;
    background: black;
    color: $color-white;
    white-space: inherit;
  }
  &.g_flyout-arrowDown-info:before,
  &.g_flyout-arrowDown-info:after {
    bottom: -8px;
    left: 31px;

    border-width: 8px 8px 0;
    border-color: $color-black1 transparent;
  }
  &.g_flyout-arrowUp-info {
    @include font-size(12, 16);

    top: 45px;
    right: inherit;
    bottom: inherit;
    left: 12px;
    margin-right: 12px;

    padding: 6px 12px 8px 12px;
    width: 195px;
    background: $color-black1;
    color: $color-white;
    white-space: inherit;
  }
  &.g_flyout-arrowUp-info:before,
  &.g_flyout-arrowUp-info:after {
    top: -8px;
    left: 16px;

    border-width: 0 8px 8px;
    border-color: $color-black1 transparent $color-black1;
  }
  &.g_flyout-arrowUp-error {
    top: 45px;
    right: inherit;
    bottom: inherit;
    left: 12px;
    margin-right: 12px;

    padding: 6px 12px 8px 12px;
    min-width: 100px;
    max-width: 320px;
    width: auto;
    background: $color-red;
    color: $color-white;
    text-align: left;
    white-space: inherit;
    line-height: 16px;

    &.gender {
      top: 25px;
    }
  }
  &.g_flyout-arrowUp-error:before,
  &.g_flyout-arrowUp-error:after {
    top: -8px;
    left: 16px;

    border-width: 0 8px 8px;
    border-color: $color-red transparent;
  }

  &.g_flyout-arrowDown-error {
    top: -66px;
    right: inherit;
    bottom: inherit;
    left: 12px;
    margin-right: 12px;

    padding: 6px 12px 8px 12px;
    min-width: 100px;
    max-width: 320px;
    width: auto;
    background: $color-red;
    color: $color-white;
    text-align: left;
    white-space: inherit;
    line-height: 16px;
  }
  &.g_flyout-arrowDown-error:before,
  &.g_flyout-arrowDown-error:after {
    bottom: -8px;
    left: 31px;

    border-width: 8px 8px 0;
    border-color: $color-red transparent;
  }
}
