.g_tooltip_trigger {
  cursor: pointer;
  display: inline-block;
  margin-left: 8px;
  margin-right: 2px;
  opacity: 1;
  transition: opacity 0.1s ease-out;

  &:hover {
    opacity: 0.8;
  }
}

.g_tooltip_transition-enter {
  opacity: 0;
  transition: 0.15s ease-in;
}

.g_tooltip_transition-enter.g_tooltip_transition-enter-active {
  opacity: 1;
}

.g_tooltip_transition-exit {
  opacity: 1;
  transition: 0.15s ease-in;
}

.g_tooltip_transition-exit.g_tooltip_transition-exit-active {
  opacity: 0;
}

.g_tooltip_panel {
  position: absolute;
  z-index: $z-tooltip-panel;

  color: $color-black1;
  cursor: default;

  $offset: 10px;
  $arrowOffset: 24px;
  $dimension-arrow: 20px;

  .g_tooltip_panel_inner {
    @include font-size(12);

    position: relative;

    padding: 16px;
    max-width: 260px;
    width: 220px;
    border: 1px solid $color-grey5;
    border-radius: $border-radius-global;
    background: white;
    text-align: left;
  }
  &.g_tooltip_panel-position-below {
    left: -$offset;

    padding-top: $offset;
    .g_tooltip_panel_arrow {
      top: -$arrowOffset;
      left: 0;

      transform: rotate(180deg);
    }
  }
  &.g_tooltip_panel-position-below-center {
    left: -$offset - 79px;

    padding-top: $offset;
    .g_tooltip_panel_arrow {
      top: -$arrowOffset;
      left: 80px;

      transform: rotate(180deg);
    }
  }
  &.g_tooltip_panel-position-below-left {
    right: -$offset - 5px;
    top: $offset * 2;

    padding-top: $offset;
    .g_tooltip_panel_arrow {
      top: -$arrowOffset;
      right: 0;

      transform: rotate(180deg);
    }
  }
  &.g_tooltip_panel-position-left {
    top: -$offset - 2;
    right: $offset * 2 + 10;
    .g_tooltip_panel_arrow {
      top: 0;
      right: -$arrowOffset - 4px;

      transform: rotate(270deg);
    }
  }
  &.g_tooltip_panel-position-left-up {
    right: $offset * 2 - 8;
    bottom: -$offset - 8;

    padding-right: $offset * 2;
    .g_tooltip_panel_arrow {
      right: -$arrowOffset - 4px;
      bottom: 0;

      transform: rotate(270deg);
    }
  }
  &.g_tooltip_panel-position-above-center {
    bottom: ($offset * 4 - 6) - 4;
    right: -97px;
    .g_tooltip_panel_arrow {
      bottom: -$arrowOffset;
      left: calc(50% - 5px);

      transform: rotate(0deg);
    }
  }
  &.g_tooltip_panel-position-above-right {
    bottom: $offset * 4 - 6;
    right: -15px;
    .g_tooltip_panel_arrow {
      bottom: -$arrowOffset - 8px;
      right: 0;

      transform: rotate(0deg);
    }
  }
  &.g_tooltip_panel-position-right {
    top: -$offset / 2 - 8;
    left: 38px;
    .g_tooltip_panel_arrow {
      top: 0;
      left: -$arrowOffset - 4px;

      transform: rotate(90deg);
    }
  }
  .g_tooltip_panel_body {
    @include font-size(14);
    @include font-regular();

    display: block;
    white-space: normal;
  }
  .g_tooltip_panel_body + .g_tooltip_panel_body {
    margin-top: 8px;
  }
  .g_tooltip_panel_link {
    @include font-regular();

    display: block;
    margin-top: 8px;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .g_tooltip_panel_title {
    @include font-size(14);
    @include font-bold();

    display: block;
    margin: 0 0 8px 0;
    white-space: normal;
  }
  .g_tooltip_panel_extraTitle {
    margin-top: 20px;
    white-space: normal;
  }
  .g_tooltip_panel_content {
    position: relative;
  }
  .g_tooltip_panel_arrow {
    position: absolute;
    &:after {
      display: block;

      border-top: 8px solid white;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      content: '';
    }
    &:before {
      position: absolute;
      top: 0;
      left: -2px;
      z-index: -1;
      display: block;

      border-top: 10px solid $color-grey5;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      content: '';
    }
  }
}
