.page_content {
  @media #{$medium-up} {
    margin: 0 24px;
  }
  @media #{$xlarge-up} {
    margin: auto;
    // magic number explanation:
    // xlarge starts at 1024px; 1024px - 24px(margin-left) - 24px(margin-right) = 976px

    max-width: 976px;
  }
}

.page_content--wide {
  @media #{$medium-up} {
    margin: 0 24px;
  }
  @media #{$xlarge-up} {
    margin: auto;
    max-width: 1048px;
  }
}
