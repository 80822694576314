// child of full_height must fill window but at same time
// be able to grow larger than it
// inspired by this http://stackoverflow.com/questions/8468066
// basically we fake some table layout as tablecells can grow larger than the
// parent table's height
.fullHeight {
  position: relative;
  display: table;

  width: 100%;
  height: 1px;
  transition: all 0.2s ease-in-out;

  .fullHeight_contentSpacing-minimal {
    padding: 32px 24px;
  }
  .fullHeight_contentSpacing-centered {
    padding: 56px 0;
  }
  .fullHeight_contentSpacing-bottom {
    padding: 210px 0 36px;
    @media #{$medium-up} {
      padding-bottom: 56px;
    }
  }
}

.moveBehindNavbar {
  margin-top: -$dimen-nav-height;
}

.disabledFullHeight .photo-fullWidth {
  padding: 36px 0;
  @media #{$medium-up} {
    padding: 96px 0;
  }
}

.fullHeight_content {
  position: relative;
  display: table;

  min-height: 100%;
  width: 100%;
  height: 100%;
  background: white;

  & > section,
  & > div {
    display: table-cell;
    vertical-align: middle;
  }
}

.fullHeight_video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;

  video {
    min-width: 100%;

    width: auto;
    height: auto;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
