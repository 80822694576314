// colors
$color-aqua: #0091b0;
$color-aquaHover: #00a5c7;
$color-aquaActive: #00839e;
$color-orange: #ffaa33;
$color-orangeHover: #ffbb5b;
$color-orangeActive: #cc8828;
$color-red: #ff3333;
$color-redHover: #ff5b5b;
$color-redActive: #cc2828;

// lightColors
$color-lightRed: #fff5f5;
$color-lightBlue: #e7f0fb;

// blacks
$color-black1: #000000;
$color-black2: #111111;
$color-black3: #1d1d1d;
$color-black4: #292929;
$color-black5: #333333;

// greys
$color-grey1: #666666;
$color-grey2: #888888;
$color-grey3: #999999;
$color-grey4: #cccccc;
$color-grey5: #e5e5e5;
$color-grey6: #f2f2f2;
$color-grey7: #f5f5f5;

// whites
$color-white: #ffffff;
$color-pearlWhite: #fafafa;

// foreigns
$color-tumblr: #34465d;
$color-twitter: #55acee;
$color-facebook1: #3a5795;
$color-facebook2: #3b5998;
$color-facebook2Hover: #4c70ba;
$color-facebook2Disabled: #96abd6;
$color-paypal: #009cde;

// unnamed
$color-unnamed1: #b2b2b2;
$color-unnamed3: rgba(255, 255, 255, 0.2);
$color-unnamed7: rgba(51, 51, 51, 0.6);
$color-unnamed8: rgba(0, 0, 0, 0.8);
$color-unnamed9: rgba(0, 0, 0, 0.6);
$color-unnamed10: rgba(0, 0, 0, 0.4);
$color-unnamed12: rgba(255, 255, 255, 0.3);
$color-unnamed13: rgba(255, 255, 255, 0.1);
$color-unnamed15: rgba(0, 0, 0, 0.1);
$color-unnamed16: rgba(0, 0, 0, 0.2);
$color-unnamed17: rgba(0, 0, 0, 0.7);
$color-unnamed24: #ff6666;
$color-unnamed28: rgba(255, 255, 255, 0.6);
$color-unnamed30: rgba(255, 255, 255, 0.5);
$color-unnamed39: rgba(0, 0, 0, 0.5);
