.photo-round {
  border-radius: 100%;
}
.photo-fullWidth {
  background-color: $color-grey4;
  background-position: center center;
  background-size: cover;
}
.photo-fullWidth-whiteBackground {
  background-color: $color-white;
}

.photo-hasGradient {
  position: relative;

  &:before {
    position: absolute;
    top: 0;
    content: '';
    height: 100%;
    width: 100%;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  .photo-hasGradient::before {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 75vh;
  } /* IE11 */
}
