/**
 * mission feature list
 */
// TODO: use pStatic instead of overriding typo_p like this.
.missionFeature {
  margin-bottom: 56px;
  min-height: 144px;

  &:nth-of-type(4) {
    @media #{$small-up} {
      margin-bottom: 0;
    }
  }
  @media #{$medium-up} {
    min-height: 196px;
    &:nth-of-type(3),
    &:nth-of-type(4) {
      margin-bottom: 0;
    }
  }
  @media #{$large-up} {
    padding: 0 24px;
    margin-bottom: 0;
  }
  .typo_p:nth-of-type(1) {
    color: $color-black5;
  }
  .typo_p {
    @include font-size(14);
  }
}

.missionNumbers {
  padding: 56px 0 66px;
  min-height: 208px;
  @media #{$medium-up} {
    padding: 77px 0 78px;
  }
  @media #{$large-up} {
    padding: 78px 0 81px;
  }
  @media #{$xlarge-up} {
    padding: 72px 0 64px;
  }
}
.missionNumbers_block {
  padding: 0 4px;
  @media #{$medium-up} {
    padding: 0 12px;
  }
}

.missionsSliderControls {
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  position: relative;
  height: 36px;
}
.mission_slider_wrapper {
  .slider_inner {
    .slider_slide:nth-of-type(1),
    .slider_slide:nth-of-type(2) {
      .landingQuote_blockquote {
        padding: 32px 0 24px;
        min-height: 208px;

        @media #{$medium-up} {
          padding: 64px 0 40px;
          min-height: 227px;
        }
        @media #{$large-up} {
          padding: 64px 0 40px;
          min-height: 241px;
        }
      }
    }
  }
}

.missionsSliderControls_inner {
  transition: all 1s ease-in-out;
  position: absolute;

  // these values are based on the
  // actual logo widths. They need to
  // be adjusted, as soon as we switch
  // logos
  &.missionsSliderControls_inner-0 {
    // motorola
    left: calc(50% - 56px);
  }
  &.missionsSliderControls_inner-1 {
    // bcg
    left: calc(50% - 210px);
  }
  &.missionsSliderControls_inner-2 {
    // applemusic
    left: calc(50% - 380px);
  }
  &.missionsSliderControls_inner-3 {
    // getty
    left: calc(50% - 546px);
  }
  &.missionsSliderControls_inner-4 {
    // huffpost
    left: calc(50% - 720px);
  }
  &.missionsSliderControls_inner-5 {
    // mercedes benz
    left: calc(50% - 920px);
  }

  @media #{$xlarge-up} {
    position: static;

    &.missionsSliderControls_inner-0,
    &.missionsSliderControls_inner-1,
    &.missionsSliderControls_inner-2,
    &.missionsSliderControls_inner-3,
    &.missionsSliderControls_inner-4,
    &.missionsSliderControls_inner-5 {
      left: 0;
    }
  }
}

.missionsSliderControls_img {
  margin: 0 36px 0 0;
  cursor: pointer;
  opacity: 0.4;
  &:hover,
  &.missionsSliderControls_img-active {
    opacity: 1;
  }
}

.missionsSliderControls_img:last-child {
  margin: 0;
}
