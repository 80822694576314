.multipleReleasesModalSubnav_left {
  text-align: left;
  margin-top: 21px;
}
.multipleReleasesModalSubnav_right {
  text-align: right;
  margin-top: 16px;
}

.multipleReleasesModalSubnav-standalone {
  position: relative;
  padding: 21px 32px;
  border-bottom: 1px solid #e5e5e5;
  overflow: hidden;
  .multipleReleasesModalSubnav_left {
    margin-top: 10px;
  }
  .multipleReleasesModalSubnav_right {
    margin-top: 0;
  }
}
