.selectGrid {
  padding: 0;
  width: 100%;
  text-align: center;
  &.selectGrid-upload {
    text-align: left;
    padding: 0 24px 24px;
  }
  &.selectGrid-contentToLeft {
    text-align: left;
  }
}

.selectGrid_imageContainer:hover .selectGrid_deletePhoto,
.selectGrid_imageContainer-touch .selectGrid_deletePhoto,
.selectGrid_deletePhoto:hover {
  display: block;
  width: 20px;
  height: 20px;
  background: $color-unnamed16;
  color: $color-white;
}

.selectGrid_photo {
  cursor: pointer;
  padding: 8px;
  border: 2px solid transparent;
  background-color: transparent;
  &.selectGrid_photo-selected {
    border: 2px solid $color-aqua;
  }
}

.selectGrid_imageContainer {
  position: relative;
  display: inline-block;

  width: 100%;
  height: 250px;
  margin: 18px 0;
  text-align: center;
  @media #{$medium-up} {
    margin: 18px 12px;
    width: 250px;
  }
  &.selectGrid_imageContainer-empty {
    background-color: $color-grey6;
    background-image: url('/node-static/img/gridplaceholder-grey.svg');
    background-position: center center;
    background-repeat: no-repeat;
  }

  .selectGrid_imageContainer_wrapper {
    position: relative;
    display: inline;
    &:before {
      display: inline-block;

      height: 100%;
      content: ' ';
      vertical-align: middle;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      transition: 0.1s ease-in-out;
    }
  }
}

.selectGrid_imageContainer:hover
  .selectGrid_imageContainer_wrapper
  .selectGrid_photo {
  filter: opacity(0.8);
}

.selectGrid_imageContainer.selectGrid_imageContainer-noHover:hover
  .selectGrid_imageContainer_wrapper
  .selectGrid_photo {
  filter: none;
  cursor: auto;
}
