body {
  background-color: $color-black1;
}

body {
  &[class*='market-checkout'],
  &.market-cart,
  &.market-download,
  &.release,
  &.upload,
  &.missions,
  &.settings:not(.settings-team),
  &[class^='m-'],
  &.embed-widget,
  &.releases,
  &.multireleases,
  &.contact,
  &.press,
  &.sell-payout {
    background-color: $color-white;
  }

  &.market-transactions,
  &.market-transaction {
    background-color: $color-grey5;
  }
}

// Custom column class for 5 columns in a 12 column grid
.small-2\.4 {
  width: 20%;
}

.br-mediumUp {
  display: none;
  @media #{$medium-up} {
    display: initial;
  }
}

.text-centered {
  text-align: center;
}

.text-centered-small {
  text-align: center;
  @media #{$medium-up} {
    text-align: inherit;
  }
}

.text-centered-medium-down {
  text-align: center;
  @media #{$large-up} {
    text-align: inherit;
  }
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-left-large-up {
  @media #{$large-up} {
    text-align: left;
  }
}

.g_fullWidth,
.row.g_fullWidth,
.row .row.g_fullWidth {
  width: 100%;
  max-width: 100%;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear-both {
  clear: both;
}

.position-relative {
  position: relative;
}

.dont-be-a-button {
  display: inline;
  margin: 0;

  padding: 0;
  width: auto;
  border: 0 !important;
  background: none !important;
  font-size: inherit;
}

.pointerEvents-none {
  pointer-events: none;
}

.overflow-hidden {
  overflow: hidden;
}
.display-none {
  display: none !important;
}

// normal content padding to reflect navbar
.content {
  @media #{$medium-up} {
    /* 1. Would use padding-top but does not apply in IE11 */
    position: relative;
    top: $dimen-nav-height; /* 1 */
  }

  &.content-noPadding {
    padding-top: 0;
  }
}

.signup-openid .content {
  top: 0;
}

.market-checkout .content,
.market-cart .content {
  position: relative;
  top: $dimen-nav-height;
}

.search:not(.is-authenticated) .content {
  /* 1. Search pages have a non-sticky nav behavior.
     2. Search pages' footer has a phantom margin which is 
        directly linked to the mysterious `.content > div`
        rule which needs to go away.
  */
  @media #{$medium-up} {
    top: inherit; /* 1 */
  }

  footer {
    margin-bottom: -$dimen-nav-height; /* 2 */
  }
}

// push navbar down when appBanner is present
.appBanner + .nav {
  top: $dimen-appBanner-height;
}

// push content down when appBanner is present
.appBanner + .nav + span + .content {
  padding-top: $dimen-nav-height + $dimen-appBanner-height;
}

// specifity needed to overtrump lp_sectionPadding
// which is defined later in the CSS file
.g_respectNavbar,
.g_respectNavbar.lp_sectionPadding {
  padding-top: $dimen-nav-height;
}

//moving this to pview_stageWrapper
#stage,
.reactRoot,
.content,
  /* this next line is causing so so so much pain, 
     can we please figure out a path to removal??  */
.content > div {
  min-height: 100%;
  height: 100%;
}

.g_greyBackground1 {
  background-color: $color-pearlWhite;
}

.g_greyBackground2 {
  background-color: $color-grey7;
}

.g_greyBackground_gradient {
  position: relative;
  &:before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    display: block;

    height: 160px;
    background-image: linear-gradient($color-unnamed15 0px, transparent 160px);
    content: '';
    opacity: 0.2;
  }
}

/* TODO:
  - deprecated,
  - could this live in frontend-components?
  - switch to styled components and flexbox -> {
    display: flex;
    align-items: center;
    height: 100%;
  }
 */
.g_centered_wrapper:before {
  display: inline-block;
  margin-right: -0.25em; /* Adjusts for spacing */

  height: 100%;
  content: '';
  vertical-align: middle;
}

/* This is also needed for centering, I not recommend to touch it */
.g_centered {
  display: inline-block;
  overflow: hidden;

  max-width: 100%;
  vertical-align: middle;
}

.g_centered_wrapper {
  height: 100%;
  text-align: center;
}

/*
 * .g_media
 * reusable media object, takes care of the commond media problem
 */
.g_media,
.g_media-body {
  overflow: hidden;
}

.g_media .g_media-left {
  float: left;
}

.g_media .g_media-left img {
  display: block;
}

.g_media .g_media-right {
  float: right;
}
