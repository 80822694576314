.settings_box {
  margin-bottom: 24px;

  padding: 16px;
  background: $color-pearlWhite;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: $color-grey7;
  }
}

.settings_box_icon {
  margin-right: 16px;
  &.settings_box_icon-inactive {
    opacity: 0.3;
    filter: grayscale(100%);
  }
}
