.collectionLinkListWrapper {
  padding-top: 16px - $typo-sub1-small;
  @media #{$medium-up} {
    padding-top: 32px - $typo-sub1-medium;
  }
  @media #{$large-up} {
    padding-top: 32px - $typo-sub1-large;
  }
  @media #{$xlarge-up} {
    margin-bottom: 56px;
  }
}

.collectionLinkList_leftAlignedColumn {
  padding-left: 9%;
  text-align: left;
}
