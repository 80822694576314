.g_link {
  fill: $color-grey3;
  color: $color-grey3;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s ease-out, fill 0.2s ease-out, opacity 0.2s ease-out;
  &:hover {
    fill: $color-grey4;
    color: $color-grey4;
  }
  &:active,
  &.g_link-active {
    fill: $color-black5;
    color: $color-black5;
  }
  &.g_link-grey {
    color: $color-grey3;
    fill: $color-grey3;
    &:hover {
      color: $color-grey4;
      fill: $color-grey4;
    }
    &:active,
    &.g_link-active {
      fill: $color-black5;
      color: $color-black5;
    }
  }
  &.g_link-onDark {
    color: $color-grey4;
    &:hover {
      color: $color-white;
    }
    &:active {
      color: $color-grey5;
    }
  }
  &.g_link-black {
    color: $color-black5;
    transition: color 0.15s ease-in-out;
    &:hover {
      color: $color-unnamed7;
    }
    &:active {
      color: $color-black5;
    }
  }
  &.g_link-aqua {
    color: $color-aqua;
    &:hover {
      color: $color-aquaHover;
    }
    &:active {
      color: $color-aquaActive;
    }
  }
  &.g_link-orange {
    color: $color-orange;
    &:hover {
      color: $color-orangeHover;
    }
    &:active {
      color: $color-orangeActive;
    }
  }
  &.g_link-red {
    color: $color-red;
    &:hover {
      color: $color-redHover;
    }
    &:active {
      color: $color-redActive;
    }
  }
  &.g_link-white {
    color: $color-white;
    transition: opacity 0.15s ease-in-out;
    &:hover {
      opacity: 0.6;
      color: $color-white;
    }
    &:active {
      color: $color-white;
    }
  }
  &.g_link-white-3 {
    color: $color-white;
    opacity: 0.6;
    transition: opacity 0.15s ease-in-out;
    &:hover {
      opacity: 1;
      color: $color-white;
    }
    &:active,
    &.g_link-active {
      opacity: 1;
      color: $color-white;
    }
  }

  &.g_link-underlined {
    text-decoration: underline;
  }
  &.g_link-download {
    &:before {
      display: inline-block;
      margin-top: 2px;
      margin-right: 8px;

      width: 14px;
      height: 16px;
      background: url('/node-static/img/download-small-aqua.svg') no-repeat
        center center;
      background-size: contain;
      content: '';
      vertical-align: top;
    }

    &.g_link-download-black:before {
      background-image: url('/node-static/img/download-small-black.svg');
    }
  }
}
