.releasePage_sidebar {
  position: absolute;
  top: 0;
  right: 0;

  min-height: 100%;
  width: 100%;

  @media #{$medium-up} {
    width: 360px;
    border-left: 1px solid $color-grey5;
  }
}

.releasePage_sidebar_top {
  padding: 0 20px 56px 20px;
}

.releasePage_sidebar_bottom {
  padding: 36px 20px 56px 20px;
  background-color: $color-grey7;
}

.releasePage_content {
  display: none;

  @media #{$medium-up} {
    position: relative;
    display: block;

    padding: 0 32px 24px 32px;
    width: calc(100% - 360px);
  }
}

.releasePage {
  position: absolute;
  right: 0;
  left: 0;
  top: $dimen-nav-height;
  @media #{$medium-up} {
    top: -$dimen-nav-height;
  }
}

.releasePage_propertyFormSeparator {
  margin: 24px 0 36px 0;

  border: none;
  border-top: 1px solid $color-grey5;
}

.releasePage_tooltip {
  .g_tooltip_panel_inner {
    max-width: 304px;
    width: 304px;

    a {
      @include font-size(12, 'none');

      display: block;
      margin-top: 10px;
    }
  }
}

.releasePage_modelForm {
  margin-top: 20px;
}

.releasePage_imageStage {
  margin-top: 36px;

  width: 100%;
  text-align: center;
  @media #{$medium-up} {
    margin-top: $dimen-nav-height;
  }
  @media #{$large-up} {
    position: relative;

    height: 100vh;
  }
}

.releasePage_imageStage_singleImage {
  width: 100%;
  @media #{$medium-up} {
    max-height: 407px;
    width: auto;
  }
  @media #{$large-up} {
    max-height: 600px;
  }
  @media #{$xlarge-up} {
    max-height: 440px;
  }
  @media #{$xxlarge-up} {
    max-height: 640px;
  }
}

.releasePage_user {
  margin: 16px 0 0 0;
}

.releasePage_user_name {
  margin: 10px 0;
}

.releasePage_user_image {
  margin-right: 8px;
}

.releasePage_submit {
  margin-top: 24px;
}

.releasePage_questions {
  @include font-size(14, 20);
  margin-top: 36px;

  color: $color-grey3;
}

.hr-releasePage {
  margin: 16px 0;

  height: 1px;
  border: none;
  background: $color-grey5;
}
