.pview_photographerBoxRow {
  margin-top: 32px;
  @media #{$medium-up} {
    margin-top: 80px;
  }
  .about {
    @include font-size(14, 22);
    margin-bottom: 8px;

    color: $color-unnamed10;
    text-align: center;
  }
}
.pview_photographerBox {
  position: relative;
  overflow: hidden;

  background-color: $color-black5;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  .pview_photographerBox_wrapper {
    margin: 0 auto;

    height: 280px;
    background-color: $color-unnamed10;
    color: $color-white;
    text-align: center;

    @media #{$medium-up} {
      height: 360px;
    }
    /* This is needed for centering, don't touch it */
    &:before {
      display: inline-block;
      margin-right: -0.25em; /* Adjusts for spacing */

      height: 100%;
      content: '';
      vertical-align: middle;
    }
    /* This is also needed for centering, don't touch it */
    .pview_photographerBox_wrapper_center {
      display: inline-block;

      vertical-align: middle;
    }
  }
  .pview_photographerBox_headline {
    @include font-regular();
    @include font-size(24, 'none');

    margin: 12px 0 4px 0;

    font-weight: normal;
    @media #{$medium-up} {
      @include font-size(32, 'none');

      margin: 16px 0 8px 0;
    }
  }
  .description {
    @include font-size(14, 20);
    margin: 0 20px;

    padding: 0 0 12px 0;
    @media #{$medium-up} {
      padding: 30px 0;
      max-width: 720px;
    }
  }
  .user-photo {
    width: 60px;
    height: 60px;
    @media #{$medium-up} {
      width: 80px;
      height: 80px;
    }
  }
  .user-link {
    @include font-size(14, 14);
    display: inline-block;
    margin: 4px 0 12px 0;

    color: $color-unnamed28;
    text-decoration: none;

    @media #{$medium-up} {
      margin: 0;
    }
  }
  .follows-you {
    display: none;
    @media #{$medium-up} {
      @include font-size(12, 24);
      display: inline-block;
      margin-left: 8px;

      padding: 0 8px;
      border-radius: $border-radius-global;
      background-color: $color-unnamed3;
      color: $color-unnamed28;
    }
  }
  .following-details {
    display: block;
    overflow: hidden;
    margin-top: 12px;

    padding-bottom: 2px;
    color: $color-unnamed10;
    @media #{$medium-up} {
      display: none;
    }
    .detail {
      float: left;
      margin-left: 32px;
      &:first-child {
        margin: 0;
      }
    }
    .title {
      @include font-size(16, 'none');
      display: block;
      margin-bottom: 4px;
    }
    .subtitle {
      @include font-size(12, 'none');
      display: block;
    }
  }
}
.static-links {
  padding: 12px 0;
  height: 44px;
  background: black;
  text-align: center;
  @media #{$medium-up} {
    display: none;
  }
  a,
  span {
    @include font-size(12, 'none');

    color: $color-white;
    text-decoration: none;
  }
  span {
    @include font-size(8, 'none');
    margin: 0 5px;
  }
}
