/* temporary dev styles */

.lightboxStrip {
  background: #0f0f11;
  border-left: 1px solid $color-black1;
  height: $dimen-lightboxstrip-height;
  z-index: $zLightboxStrip;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  opacity: 1;
  margin-top: $dimen-nav-height;
  transition: top 0.2s ease-in;
}
.transition_lightBox-enter {
  opacity: 0.01;
  top: -$dimen-lightboxstrip-height - $dimen-nav-height;

  transition: opacity 100ms step-end 200ms, top 200ms ease-out 200ms;
}
.transition_lightBox-enter.transition_lightBox-enter-active {
  opacity: 1;
  top: 0;
}

.transition_lightBox-exit {
  opacity: 1;
  top: 0;

  transition: top 200ms ease-out, opacity 100ms step-end 190ms;
}
.transition_lightBox-exit.transition_lightBox-exit-active {
  opacity: 0.01;
  top: -$dimen-lightboxstrip-height - $dimen-nav-height;
}

.lightboxGhost {
  border-top: $dimen-lightboxstrip-height solid $color-black3;
  transition: border-top 200ms linear, border-bottom 200ms linear 200ms,
    margin-bottom 200ms linear 200ms;
  @media #{$medium-up} {
    border-bottom: $dimen-nav-height solid $color-black3;
    margin-bottom: -$dimen-nav-height;
  }
}

.transition_lightboxGhost-enter {
  border-top-width: 0;
  border-bottom-width: 0;
  margin-bottom: 0;
}
.transition_lightboxGhost-enter.transition_lightboxGhost-enter-active {
  border-top-width: $dimen-lightboxstrip-height;
  border-bottom-width: $dimen-nav-height;
  margin-bottom: -$dimen-nav-height;
  transition: border-top 200ms linear 200ms, border-bottom 200ms linear,
    margin-bottom 200ms linear;
}

.transition_lightboxGhost-exit {
  border-top-width: $dimen-lightboxstrip-height;
  border-bottom-width: $dimen-nav-height;
  margin-bottom: $dimen-nav-height;
}
.transition_lightboxGhost-exit.transition_lightboxGhost-exit-active {
  border-top-width: 0;
  border-bottom-width: 0;
  margin-bottom: 0;
  transition: border-top 200ms linear, border-bottom 200ms linear 200ms,
    margin-bottom 200ms linear 200ms;
}

.lightboxStrip_right {
  position: absolute;
  right: 0;
  top: 0;
  width: 184px;
  padding: 12px;
  @media #{$medium-up} {
    width: 216px;
  }
}

.lightboxStrip_left {
  position: relative;
  padding: 12px;
  width: calc(100% - 184px);
  height: 100%;
  @media #{$medium-up} {
    width: calc(100% - 216px);
  }
  &.lightboxStrip_left-text {
    padding-left: 16px;
    padding-top: 60px;
    @media #{$medium-up} {
      padding-left: 32px;
    }
  }
}

.lightboxStrip_left_icon {
  margin-left: 8px;
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.lightboxStrip_left_float {
  width: 148px;
  bottom: 12px;
  background: linear-gradient(to right, transparent, #191919);
  position: absolute;
  right: 0;
  top: 12px;
  padding-left: 50%;
  pointer-events: none;
}

.lightboxStrip_left_photo {
  vertical-align: top;
  position: relative;
  display: inline-block;
  margin-right: 12px;
  width: auto;
  .lightboxStrip_left_photo_photo {
    transition: opacity 0.1s ease-in-out;
  }

  &:hover {
    .lightboxStrip_left_photo_trash {
      display: block;
    }
    .lightboxStrip_left_photo_photo {
      opacity: 0.5;
    }
  }
}

.lightboxStrip_left_photo_trash {
  cursor: pointer;
  display: none;
  position: absolute;
  top: 8px;
  right: 8px;
}

.lightboxStrip_left_photo_photo {
  height: $dimen-lightboxstrip-height - 24px;
}
