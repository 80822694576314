// TODO: check other usage of this one before removal
.dropdown_link {
  @include font-size(14);
  @include font-regular();

  display: block;
  margin: 0;

  padding: 5px 16px 5px 16px;
  border-radius: 2px;
  color: $color-black5;
  text-decoration: none;
  letter-spacing: 0.1px;
  cursor: pointer;
  &:hover,
  &:active {
    background-color: $color-grey6;
  }
}
