/*
 * Modal styles
 *
 * general structure:
 *  <div class="modal_wrapper modal_wrapper-open">
 *    <div class="modal_main">
 *      <div class="modal_content"></div>
 *      <div class="modal_buttonBox"></div>
 *    </div>
 *  </div>
 */

/* used on the body */
.modal-open {
  overflow: hidden;

  height: 100%;
}

/* general modal wrapper including the background cover */
.modalfade {
  opacity: 0;
  transition: opacity 0.2s ease-in;
  &.modalfade-open {
    opacity: 1;
  }
}

.modal_wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zModal;
  display: none;

  text-align: center;
  @media #{$xlarge-up} {
    // This method of centering the modal causes problems on
    // ipads where the viewport height is animated when input
    // fields get focused/unfocused, there was a bug that let the
    // whole modal disappear from time to time, therefore we only
    // center the modal on xlarge-up
    &:before {
      display: inline-block;
      margin-right: -0.25em; /* Adjusts for spacing */

      height: 100%;
      content: '';
      vertical-align: middle;
    }
  }
  &.modal_wrapper-open {
    display: block;
    overflow: scroll;
  }
}

/* the modal itself, also centers automatically */
.modal_main {
  display: inline-block;
  margin: 32px 0;
  position: relative;

  max-width: 370px;
  width: calc(100% - 64px);
  border-radius: $border-radius-modal;
  background-color: $color-white;
  vertical-align: middle;
  @media #{$medium-up} {
    width: 370px;
  }
  &.modal_main-checkoutIframe {
    // iframe width + margins
    max-width: 464px;
    width: inherit;
  }
  &.modal_main-fullScreen {
    max-width: inherit;
    width: calc(100% - 64px);
    height: calc(100% - 64px);
    min-height: 450px;
  }
  &.modal_main-wide {
    width: auto;
    width: 544px;
    max-width: 560px;
  }
}

// place for regular modal content
.modal_content {
  padding: 32px 32px 24px;
  @media #{$medium-up} {
    padding: 40px 32px 32px;
  }
  &.modal_content-noTop {
    padding-top: 0;
  }
  &.modal_content-noBottom {
    padding-bottom: 0;
  }
}

// place for modal buttons
// comes with a border on top
// takes care of padding on top/bottom and margin left/right
.modal_buttonBox {
  overflow: hidden;
  margin: 0 24px;

  padding-top: 32px;
  padding-bottom: 32px;
  border-top: 1px solid $color-grey5;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  @media #{$medium-up} {
    margin: 0 32px;

    padding-top: 16px;
    padding-bottom: 40px;
  }

  // some button boxes don't need borders and therefore no extra padding
  &.modal_buttonBox-seamless {
    padding-top: 0;
    border-top: transparent;
  }
}

// some modals use an image above the text
.modal_statusImage {
  max-width: 56px;
}

.modal_h2 {
  @include font-regular();
  @include font-size(24);

  margin: 0;

  color: $color-black1;
  &.modal_h2-white {
    color: $color-white;
  }
}

.modal_p {
  @include font-size(14);

  margin: 0;

  color: $color-grey3;

  &.modal_p-email {
    @include font-bold();

    color: $color-black5;
  }

  &.modal_p-label {
    padding-bottom: 8px;
  }
}

.modal_ul {
  padding-bottom: 8px;
  text-align: left;
  line-height: 20px;
}

.modal_close {
  position: absolute;
  top: 24px;
  right: 30px;

  fill: $color-grey4;
  cursor: pointer;
}

.hr-modal {
  margin: 12px 0 16px 0;

  height: 1px;
  border: none;
  background: $color-grey5;
}

.modal_scrollable {
  position: relative;
  margin-top: 14px;

  max-height: 250px;
  &:before {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    background: linear-gradient(transparent 230px, white);
    content: '';
    pointer-events: none;
  }
  .modal_scrollable_content {
    overflow: scroll;

    padding-bottom: 100px;
    max-height: 250px;
    text-align: left;
  }
}

// element relations
.modal_statusImage + .modal_h2 {
  margin-top: 24px;
}

.modal_h2 + .modal_p {
  padding-top: 16px;
}

.modal_h2 + .modal_ul {
  padding-top: 40px;
}

.modal_p + .modal_p {
  margin-top: 8px;
}
