//remove the cross for ie10
::-ms-clear {
  display: none;
}
::-ms-reveal {
  display: none;
}

.g_textarea {
  @include font-size(16, 24);

  &::placeholder {
    @include font-size(16, 24);
    color: $color-unnamed10;
  }

  overflow: auto;

  padding: 10px 16px;
  max-width: 100%;
  width: 100%;
  border: none;
  border-radius: $border-radius-global;
  color: $color-black5;
  resize: none;
  &:focus {
    color: $color-black5;
  }
  :empty {
    color: $color-grey3;
  }
  &:focus {
    &::placeholder {
      color: $color-unnamed16;
    }
  }
  &:focus {
    //only show these when input is focused
    & + .pview_comments-bottomLine .pview_comments-commentInstructions {
      display: inline-block;
    }
  }
  &.g_textarea-grey {
    background-color: $color-grey6;
  }
}

//one can use either .row or .g_form_row
//as form row (because .row .row inherits bad styles)
.g_form .row,
.g_form_row {
  position: relative;
  margin-top: 16px;
  &.g_form_row-largeMargin {
    margin-top: 40px;
  }
  &:first-child {
    margin-top: 0;
  }
}
.g_form {
  .columns + .columns {
    margin-top: 12px;
    @media #{$large-up} {
      margin-top: 0;
    }
    &.end {
      margin-top: 0;
    }
  }
}

.g_radio {
  display: none;
  margin-right: 16px;

  + .g_radio_label {
    position: relative;

    padding-left: 24px;
    cursor: pointer;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;

      width: 16px;
      height: 16px;
      border: 1px solid $color-grey4;
      border-radius: 12px;
      content: '';
    }
  }

  &:checked + .g_radio_label:before {
    border: 5px solid $color-aqua;
  }
  &:checked:disabled + .g_radio_label:before {
    border: 5px solid $color-grey1;
  }
}

// Make sure that g_checkbox has a relative positioned parent!
.g_checkbox {
  display: none;
  visibility: hidden;
  float: left;
  margin-right: 16px;

  + .g_checkbox_label {
    @include font-size(14);
    @include clearfix;

    display: block;
    margin: 0 0 12px 12px;

    padding-left: 16px;
    min-height: 12px;
    text-align: left;
    line-height: 22px;
    cursor: pointer;

    // this creates the custom checkbox
    &:before {
      position: absolute;
      top: 3px;
      left: 0;
      display: block;

      width: 16px;
      height: 16px;
      border: 1px solid $color-grey4;
      border-radius: 3px;
      background-color: $color-white;
      background-image: url('/node-static/img/check-white-9x7px.svg');
      background-position: center center;
      background-size: 0;
      background-repeat: no-repeat;
      content: '';
      transition: transform 0.15s ease-out, background 0.15s ease-out,
        border-color 0.15s ease-out;
    }
  }
  &.disabled + .g_checkbox_label {
    cursor: default;
  }

  input {
    opacity: 1;
  }
  &.g_checkbox-error + .g_checkbox_label:before {
    border: 1px solid $color-red;
  }
  &:checked + .g_checkbox_label:before {
    border-color: $color-aqua;
    background-color: $color-aqua;
    background-size: 9px 7px;
  }
  &:checked:disabled + .g_checkbox_label:before {
    border-color: $color-grey5;
    background-color: $color-grey5;
  }
  &.g_checkbox-mixed:checked + .g_checkbox_label:before {
    background-image: url('/node-static/img/minus.svg');
    background-size: auto;
  }
}

.g_form_orSeparator {
  width: 100%;
  text-align: center;
}

.g_form_lineSeparator {
  height: 20px;
}

.g_form_lineSeparator::after {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  top: 50%;
  border-top: 1px solid $color-grey5;
  width: 100%;
}
