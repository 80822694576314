.likers-list {
  position: relative;
  margin: 0;

  padding-bottom: 40px;
  @media #{$medium-only} {
    margin-top: 80px;
  }
  .likers-list-header {
    a {
      float: right;
    }
  }
  .likers-thumbs {
    display: inline-block;
    overflow: hidden;

    width: 100%;
    height: 40px;
    -webkit-column-width: 40px;
    -moz-column-width: 40px;
    column-width: 40px;
    -webkit-column-gap: 7px;
    -moz-column-gap: 7px;
    column-gap: 7px;

    -o-column-gap: 7px;
    -o-column-width: 40px;
    .thumb {
      width: 40px;
      height: 40px;
    }
  }
}

.likers-scrollable-list {
  position: absolute;
  top: 50px;
  right: -20px;
  z-index: $zLikersList;

  width: 376px;
  &:before {
    position: absolute;
    top: -11px;
    right: 32px;

    width: 0;
    border-width: 0 12px 12px;
    border-style: solid;
    border-color: $color-black1 transparent;
    content: '';
  }

  .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      background: $color-unnamed10;
    }
  }
  .inner {
    overflow: auto;

    max-height: 558px;
  }
  a {
    text-decoration: none;
  }
  .show-all {
    color: $color-aqua;
  }
  .liker-item {
    position: relative;
    display: block;
    overflow: hidden;

    height: 120px;
    border-top: 4px solid black;
    text-decoration: none;
    &:first-child {
      border: 0;
    }
  }
  .text {
    position: relative;
    z-index: 1;
    display: block;

    padding: 0 88px;
    height: 100%;
    .link {
      display: inline-block;

      width: 99%;
      vertical-align: middle;
    }
    .v-align {
      display: inline-block;

      height: 100%;
      vertical-align: middle;
    }
  }
  h5 {
    @include font-size(16, 24);

    margin: 0;

    color: $color-white;
    word-break: break-all;
  }
  p {
    @include font-size(14, 24);
    margin: 0;

    color: $color-unnamed28;
    word-break: break-all;
  }
  .thumb {
    position: absolute;
    top: 50%;
    left: 24px;
    z-index: 2;
    margin-top: -24px;

    width: 48px;
    height: 48px;
  }
  .action {
    position: absolute;
    top: 50%;
    right: 24px;
    z-index: 2;
    display: block;
    margin-top: -20px;
  }
}
