$beltHeight: 88px;
$detailsMediumHeight: 400px;
$sideBarWidth: 360px;

.pview_stageWrapper {
  position: relative;
  @media #{$medium-up} {
    min-height: 520px;
    height: calc(100vh - #{$dimen-nav-height});
  }
  @media #{$large-up} {
    min-height: 640px;
  }
}

.pview_stage_details {
  background-color: #0f0f11;

  @media #{$medium-up} {
    position: absolute;

    width: 50%;
    height: $detailsMediumHeight;
  }
  @media #{$large-up} {
    right: 0;

    width: $sideBarWidth;
    border-right: none;
  }
}

.pview_stage_details-market {
  &.pview_stage_details-inApp {
    position: relative;
    @media #{$medium-up} {
      position: absolute;
    }
  }
  @media #{$medium-up} {
    bottom: 0;
    left: calc(50%);

    padding: 0;
    height: $detailsMediumHeight;
    border-left: 1px solid $color-black1;
  }
  @media #{$large-up} {
    top: 0;
    bottom: auto;
    left: auto;

    height: calc(100% - 170px);
    border-bottom: 1px solid $color-black1;
    border-left: none;
  }
}

.pview_stage_details_releaseManagement {
  position: relative;

  padding: 32px 32px 56px 32px;
  height: 100%;

  @media #{$medium-up} {
    overflow: scroll;

    padding-bottom: 24px; // for inApp ReleaseManagement case (iOS)
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.pview_stage_details_top {
  position: absolute;
  top: 40px;
  right: 32px;
  bottom: auto;
  left: 32px;
  @media #{$medium-up} {
    padding-bottom: 0;
  }
  @media #{$large-up} {
    top: auto;
    // magic number to center content
    bottom: calc(50% - 38px);
  }
}

.pview_stage_details_bottom {
  top: auto;
  right: 20px;
  left: 20px;
  @media #{$medium-up} {
    bottom: 40px;
    position: absolute;
    right: 32px;
    bottom: 24px;
    left: 32px;
  }
  @media #{$large-up} {
    bottom: 20px;
  }
}

.pview_stage_details_bottom-static {
  @media #{$large-up} {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;

    height: 100%;
  }
}

.pview_stage_details-photo {
  position: relative;

  padding: 24px;

  &.pview_stage_details-photo-inApp {
    display: none;
    @media #{$medium-up} {
      display: block;
    }
  }

  @media #{$medium-up} {
    position: absolute;
    bottom: 0;
    left: 0;

    padding: 20px 32px 16px 32px;
  }
  @media #{$large-up} {
    left: auto;

    height: 170px;
  }
}

.marketDetails_user {
  position: relative;
  display: block;
  margin: 32px 0;

  text-decoration: none;
}

.marketDetails_user_thumb {
  position: absolute;
  top: 0;
  left: 0;

  width: 40px;
  height: 40px;
  img {
    width: 100%;
    height: 100%;
  }
}

.marketDetails_license {
  margin: 32px 0 0 0;
}

.pview_stage {
  overflow: hidden;

  max-width: 100%;
  @media #{$medium-up} {
    position: relative;

    padding: 65px;
    padding-top: 0;
    padding-bottom: $detailsMediumHeight + 4px;
    height: 100%;
  }
  @media #{$large-up} {
    padding-bottom: 24px;
    max-width: calc(100% - 360px);
  }
  .photo {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    @media #{$medium-up} {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;

      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }
}

.pview_stageWrapper-withContextBelt .pview_stage {
  @media #{$medium-up} {
    padding-bottom: $detailsMediumHeight + 4px + $beltHeight + 4px;
  }
  @media #{$large-up} {
    padding-bottom: 8px + $beltHeight + 8px;
  }
}

.pview_stage_feedName {
  @include font-size(14);

  display: none;

  @media #{$medium-up} {
    position: absolute;
    top: auto;
    right: auto;
    bottom: $detailsMediumHeight + 4px + $beltHeight + 4px;
    left: 8px;
    z-index: $z-pview-button + 1;
    display: block;

    padding: 2px 8px;
    border-radius: 2px;
    background: $color-unnamed3;
    color: $color-white;
    text-decoration: none;

    &:hover {
      background: $color-unnamed12;
    }
  }
  @media #{$large-up} {
    bottom: 8px + $beltHeight + 8px;
  }
}

.pview_stage_contextBelt {
  position: relative;

  height: 72px;
  @media #{$medium-up} {
    position: static;

    height: auto;
  }
}

.photo.pview_stage_contextBeltImage {
  position: absolute;
  top: auto;
  right: auto;
  bottom: 4px;
  z-index: $z-pview-button + 1;
  margin: 0;

  width: auto;
  height: 64px;
  opacity: 0.6;

  @media #{$medium-up} {
    bottom: $detailsMediumHeight + 4px;

    height: $beltHeight;
  }
  @media #{$large-up} {
    bottom: 8px;
  }
  @media #{$xlarge-up} {
    &:hover {
      transition: opacity 0.2s ease-in-out;
      opacity: 1;
    }
  }
}

.photo.pview_stage_contextBeltImage-active {
  border: 2px solid $color-white;
  opacity: 1;
}

.photo.pview_stage_contextBeltImage-nsfw {
  background-image: url('/node-static/img/nsfw.svg');
  background-position: center center;
  // fixed width so its not streched on varying container widths
  background-size: 30px auto;
}

.pview_stage_x {
  @include opacity(60);

  position: absolute;
  top: 16px;
  right: 16px;
  z-index: $z-pview-stage-x;
  display: none;

  width: 32px;
  height: 32px;
  background-image: url('/node-static/img/close.svg');
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  @media #{$medium-up} {
    display: block;
  }
  &:hover {
    transition: opacity 0.2s ease-in-out;
    @include opacity(100);
  }
}

.pview_stage_prev,
.pview_stage_next {
  display: none;

  @media #{$medium-up} {
    @include opacity(60);

    position: absolute;
    top: 0;
    z-index: $z-pview-button;
    display: block;

    width: 65px;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
      @include opacity(100);
    }
  }
}

.pview_stageWrapper-withContextBelt {
  .pview_stage_prev,
  .pview_stage_next {
    height: calc(100% - 8px - 100px - 8px - 320px);
    @media #{$large-up} {
      height: calc(100% - 8px - 100px - 8px);
    }
  }
}

.pview_stage_prev {
  left: 0;

  background-image: url('/node-static/img/prev.svg');
}

.pview_stage_next {
  right: 0;

  background-image: url('/node-static/img/next.svg');
}

.pview_wrapper {
  position: relative;
  margin-bottom: -104px;

  width: 100%;
  background-color: #0f0f11;
}

.pview_sectionHeadline {
  @include font-size(14, 14);

  display: block;
  margin-bottom: 24px;

  padding-bottom: 12px;
  color: $color-grey3;
}

/*
  TODO: rename?
  userProfile is a very unexpected name for a module that displays
  userimage + profile, image description, comments, a comment input
*/
.pview_userProfile {
  @media #{$xlarge-up} {
    margin-left: 18px;
  }
  .pview_userProfile-text {
    position: relative;
    margin: 0;

    padding: 0 0 0 0;
    @media #{$medium} {
      padding: 0 72px;
    }
    @media #{$large-up} {
      padding: 0 0 0 72px;
    }
    h4 {
      @include font-bold();
      @include font-size(14, 14);

      overflow: hidden;
      margin: 0 0 4px 0;

      padding-bottom: 2px;
      max-width: 160px;
      color: $color-black5;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 300;
      @media #{$medium-up} {
        @include font-size(20, 20);
        margin: 5px 0 6px;

        max-width: 100%;
      }
    }
  }
  .pview_userProfile-userHeader {
    overflow: hidden;
    @media #{$medium-only} {
      margin-right: -72px;
    }
  }
  .pview_userProfile-thumb {
    float: left;

    width: 32px;
    height: 32px;
    @media #{$medium-up} {
      position: absolute;
      top: 0;
      left: 0;
      float: none;

      width: 56px;
      height: 56px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .pview_userProfile-textWrap {
    margin-left: 44px;
    @media #{$medium-up} {
      margin-left: 0;
    }
  }
  .pview_userProfile-userlink {
    @include font-size(14, 16);
    margin: 4px 0 4px 0;

    color: $color-unnamed10;
    text-decoration: none;
    @media #{$medium-up} {
      @include font-size(16, 18);
      max-width: 100%;
    }
  }
}

.pview_userProfile_btn {
  float: right;
  @media #{$medium-only} {
    width: 75px;
  }
}

.pview_userProfile_btn + .pview_userProfile_btn {
  @media #{$medium-only} {
    margin: 0 0.75rem 0 0;
  }
}

.pview_description {
  @include font-regular();
  @include font-size(16, 24);

  overflow: hidden;
  margin-top: 20px;

  padding-bottom: 24px;
  border-bottom: 1px solid $color-grey5;
  color: $color-white;
  @media #{$medium-up} {
    @include font-size(24, 40);
    margin-top: 26px;

    padding-bottom: 0;
    border-bottom: none;
  }
}

.pview_comments-wrapper {
  overflow: hidden;
  margin-top: 24px;
  @media #{$medium-up} {
    margin-top: 64px;
  }
}

.pview_comments-box {
  position: relative;
  margin-top: 35px;
  .pview_comments-thumb {
    float: left;

    width: 32px;
    height: 32px;
    @media #{$medium-up} {
      position: absolute;
      top: 0;
      left: 0;
      float: none;

      width: 40px;
      height: 40px;
    }
  }
  .pview_comments-text {
    @include font-size(14, 20);
    margin: 0;

    padding: 0 0 0 44px;
    width: 100%;
    color: $color-unnamed8;
    white-space: pre-line;
    @media #{$medium-up} {
      @include font-size(16, 24);
      padding: 0 0 0 56px;
    }
    h4 {
      @include font-bold();
      @include font-size(14, 14);

      margin: 2px 0 6px 0;

      @media #{$medium-up} {
        @include font-size(16, 16);
      }
      a {
        word-break: break-all;
      }
    }
  }
  .pview_comments-bottomLine {
    @include font-size(14, 'none');
    display: none;

    @media #{$medium-up} {
      display: block;
    }
    button {
      float: right;
    }
  }
  .pview_comments-userHeader {
    overflow: hidden;
  }
  .pview_comments-textWrap {
    float: left;

    width: 70%;
  }
  .pview_comments-userlink {
    @include font-size(12, 16);
    margin: 0 0 24px 0;

    color: $color-unnamed10;
    text-decoration: none;
  }
}

.pview_comments-input-box {
  display: none;
  @media #{$medium-up} {
    display: block;
  }
  //hide until textarea has focus
  .pview_comments-commentInstructions {
    display: none;
  }
  .pview_comments-bottomLine {
    margin-top: 12px;
  }
  .pview_comments_input {
    background-color: $color-black5;
    color: $color-white;
    &:focus {
      //only show these when input is focused
      & + .pview_comments-bottomLine .pview_comments-commentInstructions {
        display: inline-block;
      }
    }
    &::placeholder {
      color: $color-grey3;
    }
  }
}

.pview_comments_key,
.upload_edit_topbar_selectAll_hint_button {
  display: inline-block;
  margin-top: 5px;

  padding: 1px 3px;
  border: 1px solid $color-unnamed10;
  border-radius: $border-radius-global;
}

.pview_btn-share {
  width: 100%;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 1;
  }
}
