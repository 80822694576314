//use mixin because autoprefix doesn't know
//about these old prefixes. we use these because ie10 has
//partial support and old android 4 needs a dif one
@mixin display-flex() {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin flex($value) {
  -webkit-box-flex: $value;
  -webkit-flex: $value;
  -moz-box-flex: $value;
  -moz-flex: $value;
  -ms-flex: $value;
  flex: $value;
}

@mixin text-overflow() {
  overflow: hidden;

  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//mixin that warns of unitless arguments
@mixin require-units($value) {
  @if (unitless($value)) {
    @warn 'units required';
  }
}
// mixin that calculates font-size and line-height based on the "typo value"
// The "typo value" is used on landing pages and is a value that defines
// margin-bottom, font-size and line-height
@mixin font-size-typovalue($typovalue) {
  @include require-units($typovalue);
  font-size: rem-calc($typovalue * 2 - 8);
  line-height: rem-calc($typovalue * 2);
}

//mixin that automatically adds a line-height (if not passed)
//8px greater than font-size  and converts to rem
@mixin font-size($font-size, $line-height: false) {
  @if $line-height == false {
    $line-height: $font-size + 8px;
  }

  font-size: rem-calc($font-size);

  @if $line-height != 'none' {
    line-height: rem-calc($line-height);
  }
}

// Opacity
@mixin opacity($opacity) {
  opacity: $opacity / 100;
}

// vertical align elements
@mixin vertical-align {
  position: relative;
  top: 50%;

  transform: translateY(-50%);
}

@mixin horizontal-center {
  left: 50%;

  transform: translateX(-50%);
}

$font-light: 'Sailec Light', sans-serif;
$font-regular: 'Sailec Regular', sans-serif;
$font-bold: 'Sailec Bold', sans-serif;

@mixin font-regular() {
  font-weight: 300;
  font-family: $font-regular;
}

@mixin font-bold() {
  font-weight: 300;
  font-family: $font-bold;
}
