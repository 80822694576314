// a file to specify all z-indices as variables

$photocardFeedbackTrigger: 5;
$z-cw_disabled: 3;
$z-form-error: 8;
$z-help_bubble: 4;
$z-landing-scroll: 5;
$z-landing-sections: 1;
$z-pview-button: 1;
$z-pview-stage-x: 2;
$z-tooltip-panel: 9;
$z-tooltip: 3;
$zBlacklistCheck: 1;
$zBlacklistCheckPhoto: 2;
$zDropdown: 9;
$zDropdownSelectItems: 10;
$zDropdownSelectTriggerArrow: 0;
$zFlyout: 3;
$zLightboxStrip: 10;
$zLikersList: 5;
$zModal: 30;
$zModalOuter: 13;
$zNavbar: 11;
$zNotificationBar: 12;
$zPhotoCardImage: 1;
$zPhotoCardReleasesNote: 3;
$zSubnav: 8;
$zUploadTopbar: 7;
