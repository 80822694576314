.dropdownSelect_item {
  @include font-size(14, 32);
  cursor: pointer;
  display: block;
  position: relative;
  padding: 2px 0;
  color: $color-black1;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: $color-grey6;
    .dropdownSelect_item_label_hoverLink {
      display: block;
    }
  }
}

.dropdownSelect_item_label_hoverLink {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  padding-left: 44px;
  padding-right: 12px;
  padding-top: 8px;
  background: linear-gradient(to right, transparent 0%, $color-black2 40%);
  height: 36px;
}
