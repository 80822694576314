.userBadge {
  position: relative;
  margin-bottom: 20px;

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  .userBadge_content {
    margin: 0 auto;

    padding: 56px;
    height: 100%;
    background-color: $color-unnamed10;
  }
  .userBadge_content_thumb {
    z-index: 2;
    margin: 0 auto 8px auto;

    width: 80px;
    height: 80px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .userBadge_content_headline {
    overflow: hidden;

    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .userBadge_content_followsYou {
    display: none;
    @media #{$medium-up} {
      @include font-size(12, 24);
      display: inline-block;
      margin-left: 8px;

      padding: 0 8px;
      border-radius: $border-radius-global;
      background-color: $color-unnamed3;
      color: $color-unnamed28;
    }
  }
  .userBadge_content_followBtn {
    margin-top: 32px;
  }
  .userBadge_content_counterList {
    margin-top: 20px;
  }
  .userBadge_content_counterList_count {
    @include font-size(20, 'none');

    display: block;

    color: $color-unnamed30;
  }
  .userBadge_content_counterList_label {
    @include font-size(12, 'none');
    color: $color-unnamed30;
  }
}
