.notFound {
  @include font-regular();

  position: relative;

  background-image: url('/node-static/img/404/desktop_404-by-paubuscato.jpeg');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;

  height: 100vh;

  .g_centered_wrapper {
    height: 100%;
    background-color: $color-unnamed10;
  }
  h2 {
    @include font-size(20, 24);

    margin: 40px 20px;

    max-width: 800px;
    color: $color-white;
    font-weight: 300;

    @media #{$medium-up} {
      @include font-size(32, 40);

      margin: 80px 20px 0 20px;
    }
  }
  .notFound_footer {
    @include font-regular();

    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 20px;
    .notFound_supportLink {
      @include font-size(14, 'none');

      color: $color-white;
      text-align: center;

      @media #{$medium-up} {
        margin-bottom: 20px;
      }
    }
    .notFound_credits {
      @include font-size(14, 'none');

      text-align: center;
      @media #{$medium-up} {
        position: absolute;
        bottom: 5px;
        left: 5px;
      }
      &,
      & .g_link {
        color: $color-unnamed28;
      }
    }
  }
}
