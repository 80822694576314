.header {
  position: relative;
  height: 400px;

  @media #{$large-up} {
    height: 320px;
  }
  background-color: $color-black5;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: -$dimen-nav-height;
}

.header_content {
  position: relative;

  padding-top: $dimen-nav-height;
  height: 100%;
  background: $color-unnamed10;

  @media #{$large-up} {
    background: linear-gradient(
      to bottom,
      $color-unnamed39 0,
      transparent 72px,
      $color-unnamed39 100%
    );
  }

  .header_content_main {
    margin: 0 auto;
    height: 100%;

    /* This is needed for centering, don't touch it */
    &:before {
      display: inline-block;
      margin-right: -0.25em; /* Adjusts for spacing */

      height: 100%;
      content: '';
      vertical-align: middle;
    }
  }

  /* This is also needed for centering, don't touch it */
  .header_content_main_center {
    display: inline-block;

    padding: 0 32px;
    width: 100%;
    vertical-align: middle;
    text-align: center;
    @media #{$large-up} {
      position: absolute;
      bottom: 0;
      left: 0;

      padding: 32px;
      min-height: 104px; // height of user thumb, so that album header has the same alignment
      height: auto;
      text-align: left;
    }
  }
  .header_content_main_thumb {
    margin-bottom: 8px;

    width: 64px;
    height: 64px;
    @media #{$large-up} {
      float: left;
      margin-right: 24px;
      margin-bottom: 0;

      width: 104px;
      height: 104px;
    }
  }
  .header_content_main_icon {
    margin-bottom: 12px;

    width: 40px;
    height: 40px;
    @media #{$large-up} {
      float: left;
      margin-top: 22px;
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
  .header_content_main_headline {
    margin-top: 8px;
    margin-bottom: 2px;
    @media #{$large-up} {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
    }
    .header_content_main_headline_fullname {
      overflow: hidden;

      max-width: 380px; // leaving space for FollowsYou
      vertical-align: middle;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .header_content_main_navLinks {
    display: inline-block;
    margin-top: 8px;

    width: 100%;
    @media #{$large-up} {
      display: inline;
      margin-top: 0;

      width: auto;
    }
  }
  .header_content_main_bio {
    display: none;
    @media #{$large-up} {
      overflow: hidden; // e.g. long links on mobile
      margin-top: 8px;

      width: 400px;
    }
  }
}

.header_subnavButton {
  padding-top: 12px;
  padding-right: 20px;
  @media #{$medium-up} {
    padding-right: 24px;
  }
  @media #{$large-up} {
    padding-right: 32px;
  }
}

.header_subnavInfo {
  text-overflow: ellipsis;
  padding-top: 12px;
  padding-left: 20px;
  @media #{$medium-up} {
    padding-left: 24px;
  }
  @media #{$large-up} {
    padding-left: 32px;
  }
  width: 30%;
  text-align: left;
  .header_subnavInfo_thumb {
    margin-top: 4px;
    margin-right: 12px;
    &.header_subnavInfo_thumb-album {
      border-radius: 2px;
    }
  }
  .header_subnavInfo_text {
    overflow: hidden;

    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
