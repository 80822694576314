.contact_supportLink {
  padding: 0;
  &.contact_supportLink-0 {
    @media #{$medium-up} {
      padding-right: 16px;
    }
    @media #{$large-up} {
      padding-right: 24px;
    }
  }
  &.contact_supportLink-1 {
    @media #{$medium-up} {
      padding-left: 8px;
      padding-right: 8px;
    }
    @media #{$large-up} {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
  &.contact_supportLink-2 {
    @media #{$medium-up} {
      padding-left: 24px;
      padding-right: 0;
    }
    @media #{$large-up} {
      padding-left: 24px;
      padding-right: 0;
    }
  }
}

.contact_socialLinkBox {
  border-radius: 2px;
  display: inline-block;
  margin: 24px 12px 0 12px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  color: $color-white;

  background-color: $color-grey3;
  transition: background-color 0.2s linear;
  text-decoration: none;
  @media #{$medium-up} {
    height: 48px;
    width: 48px;
    margin: 24px 18px 0 18px;
    line-height: 48px;
  }
  &:hover {
    text-decoration: none;
    &.contact_socialLinkBox-facebook {
      background-color: $color-facebook1;
    }
    &.contact_socialLinkBox-tumblr {
      background-color: $color-tumblr;
    }
    &.contact_socialLinkBox-eyeem {
      background-color: $color-black1;
    }
    &.contact_socialLinkBox-twitter {
      background-color: $color-twitter;
    }
  }
}

.hr-contact {
  border: none;
  height: 1px;
  background: $color-grey5;
  margin: 36px 0;
  @media #{$medium-up} {
    margin: 56px 0;
  }
}

.contact_office {
  margin-bottom: 36px;
  padding: 0;
  &:last-child {
    margin-bottom: 0;
  }

  @media #{$medium-up} {
    margin-bottom: 0;
  }
  &.contact_office-0 {
    @media #{$large-up} {
      padding-right: 24px;
    }
  }
  &.contact_office-1 {
    @media #{$large-up} {
      padding-left: 12px;
      padding-right: 12px;
    }
  }
}

.contact_office_map {
  margin-bottom: 16px;
  @media #{$medium-up} {
    padding-right: 24px;
    margin-bottom: 56px;
  }
  @media #{$large-up} {
    padding-right: 0;
    margin-bottom: 24px;
  }
}
