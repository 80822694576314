.slider_wrapper {
  position: relative;
  overflow: hidden;
}
.slider_slide {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;

  &:first-child {
    position: relative;
    bottom: auto;
  }
}
.slider_inner {
  position: relative;
  transition: transform cubic-bezier(0.42, 0, 0.58, 1) 1s;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
}

.slider_control {
  @extend .g_btn;
  @extend .g_btn-blackTrans;

  // reset max height until we remove it from g_btn
  max-height: inherit;
  width: 40px;
  height: 64px;
  margin: 0;
  padding: 0;
  border: none;
  position: absolute;
  z-index: 1000;
  top: 50%;
  background-repeat: no-repeat;
  background-position: center center;
}

.slider_control-prev {
  background-image: url('/node-static/img/prev.svg');
  left: 10px;
}

.slider_control-next {
  background-image: url('/node-static/img/next.svg');
  right: 10px;
}

.slider_slide .imageBreak_text {
  opacity: 0;
  transition: opacity 0.5s;
}

.slider_slide-active .imageBreak_text {
  opacity: 1;
  transition: opacity 0.5s 1s;
}

.slider_control-default {
  width: 6px;
  height: 6px;
  background: black;
  display: inline-block;
  opacity: 0.2;
  border-radius: 100%;
  margin-left: 6px;

  &:first-child {
    margin: 0;
  }

  &.active {
    opacity: 0.4;
  }
}
