.subnav {
  z-index: $zSubnav;

  width: 100%;
  border-bottom: 1px solid $color-grey5;
  background-color: $color-white;
  &.subnav-night {
    border-bottom: 1px solid $color-black5;
    background-color: #191919;
    border-left: 1px solid $color-black1;
  }
  .subnav_sideInfo {
    position: absolute;
    top: 0;
    visibility: hidden;
    opacity: 0;
    margin-top: 0;
    transition: opacity 0.2s ease-in-out;

    &.subnav_sideInfo-dontHide {
      @media #{$xlarge-up} {
        opacity: 1;
        visibility: visible;
      }
    }

    &.subnav_sideInfo-right {
      right: 0;
    }

    &.subnav_sideInfo-left {
      left: 0;
    }
  }
  &.subnav-fixed .subnav_sideInfo {
    @media #{$xlarge-up} {
      opacity: 1;
      visibility: visible;
    }
  }
}

.subnav_list {
  padding-left: 16px;
  @media #{$xlarge-up} {
    padding-left: 20px;
  }
  &.subnav_list-collapsible {
    margin-bottom: 14px;

    padding-top: 10px;
    padding-bottom: 24px;
    border-bottom: 1px solid $color-grey5;
  }
}

.subnav_list_element {
  position: relative;
  margin-bottom: 11px;

  &:last-child {
    margin-bottom: 0;
  }
}

.subnav_list_element-active:before {
  position: absolute;
  top: 12px;
  left: -12px;
  display: inline-block;

  width: 3px;
  height: 3px;
  border-radius: 3px;
  background-color: $color-black5;
  content: '';
}

.subnav_item {
  display: inline-block;
  margin: 0 24px 0 0;

  padding: 15px 0 15px 0;
  border-bottom: 3px solid transparent;

  .subnav_item_img {
    opacity: 0.5;
  }

  &:hover {
    .subnav_item_img {
      opacity: 0.3;
    }
  }

  &.subnav_item-last {
    margin-right: 0;
  }

  @media #{$medium-up} {
    margin: 0 40px 0 0;

    padding: 21px 0 18px 0;
    &.subnav_item-largerMargin {
      margin: 0 56px 0 0;
      &.subnav_item-last {
        margin-right: 0;
      }
    }
  }
  &:active,
  &.subnav_item-active {
    border-bottom: 3px solid $color-unnamed8;
    .subnav_item_img {
      opacity: 1;
    }
  }
  &.collapsible {
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
    border-top: none;
    border-bottom: none;
    background: white;
    @media #{$medium-up} {
      padding: 24px 0 18px 0;
    }
  }

  &.collapsible-open {
    border-bottom: 1px solid $color-grey5;
  }
}

.subnav.subnav-night .subnav_item-active {
  border-bottom: 3px solid $color-white;
}
