$dimen-nav-height: 64px;
$dimen-appBanner-height: 56px;
$dimen-lightboxstrip-height: 160px;

$dimen-search-container-height: 104px;

$border-radius-global: 2px;
$border-radius-modal: 4px;
$border-radius-widget: 4px;

$sideNavWidth: 64px;
$openSideNavWidth: 240px;
