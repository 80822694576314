/**
 * Here you find code for the collection and mission grids on landing pages,
 * hiding and show things with opacity is a must because we have weird display-table
 * centering here. It will not work mit display none and table switch
 * TODO: Split into three files: verticalGridImage, mlp_missions
 */

.lp_verticalGridImage {
  margin-bottom: 4px;
  position: relative;
  .photo:not(.communityPromoGrid_avatar) {
    width: 100%;
  }
  .lp_verticalGridImage_hoverBox {
    // TODO: Needs refactoring. (Do not use typography classes like this)
    .typo_cap img {
      height: 14px;
      margin-left: 8px;
    }
    @media #{$small-only} {
      .typo_p {
        @include font-regular();
        @include font-size(12);
        // we need to do this explicitly, cause there's
        // a hidden .typo_cap coming afterwards (see below)
        margin-bottom: 0;
      }
      .typo_cap {
        display: none;
      }
    }
    background-color: $color-black1;
  }
  @media #{$medium-up} {
    &:hover {
      .lp_verticalGridImage_hoverBox {
        background-color: $color-unnamed9;
      }
    }
  }
  &:active {
    .lp_verticalGridImage_hoverBox {
      background-color: $color-unnamed9;
    }
  }
}

.lp_verticalGridImage-contentWrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.lp_verticalGridImage_hoverBox {
  background-color: $color-unnamed9;
  display: table;
  width: 100%;
  height: 100%;
  text-decoration: none;
  // TODO: Needs refactoring. (Do not overwrite tyopgraphy classes like this,
  // create proper styleguide exceptions!)
  .typo_p {
    color: $color-white;

    padding: 0 12px;
  }
}

.collectionSectionHeader {
  @media #{$xlarge-up} {
    position: relative;
  }
}

.collectionPreviewBoxes {
  margin: 32px -8px 0 -8px;
  @media #{$large-up} {
    margin-top: 56px;
  }
}

.collectionPreviewBox {
  display: block;
  margin: 0 8px 20px 8px;
}

.collectionPreviewBox-ratioHelper {
  width: 100%;
  padding-bottom: 75%; /* 4/3 */
  position: relative;
}

.collectionPreviewBox-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url('/node-static/img/gridplaceholder-grey.svg');
  background-position: center center;
  background-repeat: no-repeat;
}

.collectionPreviewBox-label {
  padding: 15px 16px 17px 16px;
  background-color: #191919;
}
