/*
 * styles for previous purchases page
 * it reuses styles from _checkout.scss
 */

.pp_content {
  min-height: 470px;
  margin-bottom: 32px;
}

.pp_empty {
  padding: 170px 64px 0;
  text-align: center;
  p {
    @include font-regular();
    @include font-size(20, 28);
  }
}
.pp_headline {
  margin-top: 48px;
  margin-bottom: 32px;
  @include font-size(20);
}

.pp_item {
  padding: 24px 0;
  border-top: 1px solid $color-grey5;
  margin: 0 24px;

  &:first-child {
    border-top: none;
  }

  .pp_itemHeadline {
    margin: 0 0 12px;
    font-weight: rem-calc(20);

    .g_price {
      white-space: nowrap;
      @include font-bold();
      &.g_price-pending {
        color: $color-grey5;
      }
    }
  }

  .pp_licenseLabel {
    @include font-size(14, 'none');
    color: $color-grey3;
  }

  .pp_downloadLink {
    @include font-size(14, 'none');
    background: url('/node-static/img/download-small-aqua.svg') center left 24px
      no-repeat;
    display: inline-block;
    padding-left: 44px;
    margin-left: -24px;

    .pp_downloadLink-label {
      display: none;
    }
    @media #{$large-up} {
      .pp_downloadLink-label {
        display: inline;
      }
    }
    &.pp_downloadLink {
      margin-left: 0px;
    }
  }

  .pp_itemBody {
    margin-top: 32px;
    .g_media {
      margin: 16px 0;
      border-bottom: 1px solid $color-grey5;
      padding-bottom: 16px;
      &:last-child {
        border: none;
        margin-bottom: -8px;
        padding-bottom: 0;
      }
    }
    .g_media-body {
      margin-top: 24px;
    }
    .pp_licenseLabel {
      @include text-overflow();
      display: inline-block;
      padding: 2px 0;
      margin-right: 4px;
    }
  }
  .pp_item_photo {
    width: 64px;
    margin-right: 16px;
  }
}
